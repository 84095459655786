import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
export const useCompetitionViewStore = defineStore("competitionView", {
  state: () => ({
    competition: {
      name: '',
      description: '',
      image: '',
      rewards: [],
    },
    users: [], 
    rewardss: [],
  }),
  actions: {
    async getCompetitions(id) {
      try {
        const response = await axios.post(`fetch_competition_details`, { id: id });
        if (response.data.status === true) {
          this.competition = response.data.data;
        } else {
          this.competition = {};
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getusers() {
      try {
        const response = await axios.post(`fetch_users`);
        if (response.data.status === true ) {
          this.users = response.data.data.data;
        } else {
          this.users = []; 
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error);
        this.users = []; 
      }
    },
    async addrewards(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
    
        const response = await axios.post("assign_competition_reward", formData);
        if (response.data.status === true) {
          await router.push("/");
          this.rewardss = response.data.data.data;
          Swal.fire({
            icon: "success",
            title: "Rewards added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message || "An error occurred.",
        });
      }
    }
    },
});
