<template>
  <HeadersPages
    title="بنك الاسئلة"
    button="+ اضافة بنك الاسئلة"
    link="/add-question-banks"
  />

  <div class="alll">
  <div class="search">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="بحث عن  سؤال..."
        v-model="word"
        @input="debouncedSearch"
      />
    </div>
  <TablesPageVue
    :headers="tableHeaders"
    :rows="tableRows"
    :pages="tablePages"
    :showSelect="false"
    :editLink="editLink"
    :viewLink="viewLink"
    @delete="handleDeleteQuestionBanks"
    :ismaster="ismaster"
  />
  <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  <PaginationPage
    :currentPage="paginationCurrent"
    :totalPages="paginationLast"
    @page-changed="handlePageChange"
  />
</div>

</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
// import { useQuestionBanksStore } from "@/stores/question_banks/QuestionBanksStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { useQuestionsBankStore } from "@/stores/question_bank/QuestionBankStore";
import { useExamsStore } from "@/stores/exams/ExamsStore";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "QuestionBanksIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      errorMessage: "",
      word : "",
      debouncedSearch : null,
      tableHeaders: ["ID", "السؤال", "النوع", "الدرجة", "مقرر", "الموسم"],
      editLink: "/edit-question-banks",
      viewLink: "/question-banks-view/",
    };
  },
  computed: {
    ...mapState(useQuestionsBankStore, {
      questions_bank: (state) => state.questions_bank,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      const dataToDisplay = this.questions_bank;
      return dataToDisplay.map((exa) => [
        exa.id,
        exa.question,
        exa.type,
        exa.degree,
        exa.curriculum.title,
        exa.season.title,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const store = useQuestionsBankStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await store.fetchQuestionsBank(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await store.fetchQuestionsBank(1, this.word);

      if (store.questions_bank.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    handlePageChange(page) {
      const store = useQuestionsBankStore();
      store.fetchQuestionsBank(page);
    },
    // handlePageChange(page) {
    //   this.$store.commit("setPaginationCurrent", page);
    //   this.$store.dispatch("getQuestionBanks");
    // },
    async handleDeleteQuestionBanks(id) {
      const store = useQuestionsBankStore();
      await store.deleteQuestion(id);
    },
  },
  async mounted() {
    const store = useQuestionsBankStore();
    await store.fetchQuestionsBank();

    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>

<style scoped lang="scss">
table.table {
    & thead {
        & tr {
            th {
                background: var(--main) !important;
                color: white;
                text-align: start !important;
            }
        }
    }
}
.error-message {
  color: white;
  background-color: #ef0000a3;
  margin-top: -39px;
  margin-right: 42px;
  width: 95%;
  margin-bottom: -25px;
  padding: 8px !important;
  text-align: center;
  border-radius: 3px;
}
</style>
