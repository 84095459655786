<template>
  <div class="courses">
    <header-pages title="الدورات التعليميه" button="اضافة دوره" link="/add-course" />
    <div class="alll">

    <div class="search">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="بحث عن دورة تعليميه..."
        v-model="word"
        @input="debouncedSearch"
      />
    </div>
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteGroup"
    />
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</div>

</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useCoursesStore } from "@/stores/courses/CoursesStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "CoursesIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word : "",
      errorMessage: "",
      debouncedSearch : null,
      tableHeaders: [
        "ID",

        "اسم الدورة",
        "السنوات الدراسيه ",
        "الفصول الدراسيه ",
        "المنهج",
        "الاعاقات",
        "المراحل",
      ],
      editLink: "/edit-courses",
      viewLink: "/view-course",
    };
  },
  computed: {
    ...mapState(useCoursesStore, {
      courses: (state) => state.courses,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      const dataToDisplay = this.courses;
      return dataToDisplay.map((cor) => [
        cor.id,
        cor.name,
        cor.year?.title,
        cor.season?.title,
        cor.curriculum?.title,
        cor.disability_types?.map((dt) => dt.title).join(", "),
        cor.stages?.map((dt) => dt.title).join(", "),
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const coursesStore = useCoursesStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await coursesStore.fetchCourses(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await coursesStore.fetchCourses(1, this.word);

      if (coursesStore.courses.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    

    handlePageChange(page) {
      const coursesStore = useCoursesStore();
      coursesStore.fetchCourses(page);
    },
    handleDeleteGroup(id) {
      const coursesStore = useCoursesStore();
      coursesStore.deleteCourse(id);
    },
  },

  mounted() {
    const coursesStore = useCoursesStore();
    coursesStore.fetchCourses();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>

<style scoped>
.error-message {
  color: white;
  background-color: #ef0000a3;
  margin-top: -39px;
  margin-right: 23px;
  width: 97.4%;
  margin-bottom: -25px;
  padding: 8px;
  text-align: center;
  border-radius: 3px;
}
</style>
