import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
export const useSubHeadersAddStore = defineStore("subHeadersAdd", {
  state: () => ({
    subHeaders: [],
  }),
  actions: {
    async addSubHeader(data) {
      try {
        // إنشاء كائن FormData جديد
        const formData = new FormData();

        // إضافة البيانات العادية
        formData.append("title", data.title);
        formData.append("subtitle", data.subtitle);
        formData.append("description", data.description);

        // إضافة الصورة الرئيسية إذا كانت موجودة
        if (data.image) {
          formData.append("image", data.image); // إضافة الصورة الرئيسية
        }

        // إضافة ميزات الصور
        data.features.forEach((feature, index) => {
          if (feature.file) {
            formData.append(`features[${index}][image]`, feature.file); // إضافة صور الميزات
            formData.append(`features[${index}][title]`, feature.title); // إضافة عنوان الميزات
            formData.append(
              `features[${index}][description]`,
              feature.description
            ); // إضافة وصف الميزات
          }
        });

        // إرسال البيانات باستخدام Axios
        await axios.post("organization_add_subheader", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // تأكد من أن المحتوى متوافق
          },
        });

        // عرض رسالة نجاح
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Sub Header Added Successfully",
          showConfirmButton: false,
          timer: 1500,
        });

        // إعادة توجيه المستخدم إلى صفحة الفهارس
        router.push("/whowe");
      } catch (error) {
        console.log(error);
        // عرض رسالة خطأ في حال حدوث مشكلة
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Something went wrong!",
        // });
      }
    },
  },
});
