import { defineStore } from "pinia";
import axios from "axios";

export const usecomplaintsStore = defineStore("complaints", {
  state: () => ({
    complaints: [],
  }),
  actions: {
    async getComplaints() {
      try {
        const response = await axios.post("fetch_contacts");
        console.log(response.data.status);

        if (response.data.status) {
          this.complaints = response.data.data;
          console.log(this.complaints);
        } else {
          console.error("Failed to fetch complaints:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching complaints:", error);
      }
    },
  },
});
