<template>
  <div v-if="visible" class="spinner-loader">
    <div class="spinner">
      <img src="@/assets/photos/logo.png" alt="" />
    </div>
    <h4>منصة شفيعا</h4>
  </div>
</template>

<script>
export default {
  name: "SpinnerLoader",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.spinner-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.575);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}
h4 {
  font-family: "bold";
  margin-top: 10px;
  color: var(--main);
}
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
