<template>
  <HeaderPages :showButton="false" title="عرض المسابقة " />

  <div class="CompetitionView">
    <div class="inpu" v-if="competition && Object.keys(competition).length">
      <img :src="competition.image" alt="" />
      <h3><span> اسم المسابقة :</span> {{ competition.name }}</h3>
      <h4><span> الوصف :</span> {{ competition.description }}</h4>
      <h4><span>الجوائز:</span></h4>
  
      <ul v-if="Array.isArray(competition.rewards)">
        <li v-for="(reward, index) in competition.rewards" :key="index">
        
          <h4><span>{{ getStageText(reward.stage) }} :</span></h4>
          <input
            type="checkbox"
            :id="'reward-' + index"
            :checked="selectedReward === reward.id"
            :value="reward.id"
            @change="selectOnlyOne(reward.id)"
          />

          <label :for="'reward-' + index">{{ reward.reward }}</label>
        </li>
      </ul>
      <div class="col-lg-12 col-md-6 col-sm-12">
        <label for="">اختار طالب : </label>

        <multiselect
          v-model="user"
          :options="usersOptionss"
          label="name"
          track-by="id"
        ></multiselect>
        <div v-if="errorMessage" class="error-message">
  {{ errorMessage }}
</div>

      </div>
      <div class="all-btn">
        <button class="save" @click="handleSubmit">حفظ</button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useCompetitionViewStore } from "@/stores/competition/CompetitionViewStore";
import { mapState } from "pinia";

export default {
  data() {
    return {
      store: useCompetitionViewStore(),
      user: null,
      usersOptionss: [],
      selectedReward: null,
      errorMessage: '',
    };
  },
  name: "CompetitionView",
  components: {
    HeaderPages,
    Multiselect,
  },
  computed: {
    ...mapState(useCompetitionViewStore, {
      competition: (state) => state.competition,
      users: (state) => state.users,
    }),
  },
  methods: {
    getStageText(stage) {
    if (stage === 1) {
      return 'المركز الأول';
    } else if (stage === 2) {
      return 'المركز الثاني';
    } else if (stage === 3) {
      return 'المركز الثالث';
    } else {
      return '';}
  },
    selectOnlyOne(rewardId) {
      if (this.selectedReward === rewardId) {
        this.selectedReward = null;
      } else {
        this.selectedReward = rewardId;
      }
    },
    async fetchusers() {
      try {
        await this.store.getusers();
        console.log(this.store.users);
        this.usersOptionss = this.store.users;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    async handleSubmit() {
  if (!this.user) {
    this.errorMessage = "من فضلك حدد طالبا";
    
    return;
  } else {
    this.errorMessage = "";
  }

  if (!this.selectedReward) {
    this.errorMessage = "من فضلك حدد الجوائر";
    return;
  }

  const data = {
    user_id: this.user.id,
    competition_reward_id: this.selectedReward, 
  };

  try {
    await this.store.addrewards(data);
    console.log("Data to be submitted:", data);
  } catch (error) {
    console.error("Error submitting data:", error);
  }
}

  },
  async mounted() {
    const CompStore = useCompetitionViewStore();
    try {
      const id = this.$route.params.id;
      await CompStore.getCompetitions(id);
    } catch (error) {
      console.error("Error fetching competition data:", error);
    }

    await this.fetchusers();
  },
};
</script>

<style scoped>
h3,
h4 {
  font-size: 18px;
  margin-top: 14px;
}

ul {
  list-style: none;
}

.CompetitionView {
  width: fit-content;
  border: 1px solid #ebeaed;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
}

img {
  width: 400px;
  border-radius: 10px;
}
.error-message {
  color: red;
  margin-top: 10px;
}

.save {
  margin-inline-start: 0 !important;
  margin-top: 10px;
  width: 100%;
}
</style>
