<template>
  <div class="Groups-add">
    <header-pages title="تعديل اسكرين" :showButton="false" link="/edit-screen" />
    <form action="" @submit.prevent="updateheader">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <span class="error-feedback" v-if="v$.form.image.$error">{{
              getErrorMessage(v$.form.image)
            }}</span>
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save" @click="Edit()">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { mapState } from "pinia";
import { useScreenEditStore } from "@/stores/Screenss/screenEditStore";
import Swal from "sweetalert2";

export default {
  components: { HeaderPages },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        image: null,
        imageSrc: "",
      },
    };
  },
  validations() {
    return {
      form: {
        image: { required },
      },
    };
  },
  computed: {
    ...mapState(useScreenEditStore, {
      screen: (state) => state.screen,
    }),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    async fetchEditData() {
      const store = useScreenEditStore();
      const id = this.$route.params.id;
      await store.getScreen(id);
      this.form = { ...store.screen };
      this.form.imageSrc = store.screen.image;
    },
    async updateheader() {
      // if (!this.form.image) {
      //   Swal.fire("Error", "Please fill in all fields", "error");
      //   return;
      // }

      const store = useScreenEditStore();
      const formData = new FormData();

      formData.append("id", this.form.id);
      if( this.form.image instanceof File ) {

        formData.append("image", this.form.image);
      }

      await store.updateScreen(formData);

    },
    Edit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
  },
  mounted() {
    this.fetchEditData();
  },
};
</script>

<style>
</style>