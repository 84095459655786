<template>
  <div class="add_teacher">
    <HeaderPages title=" إضافة اراء العملاء" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />

            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صوره</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <span class="error-feedback" v-if="v$.form.imageSrc.$error">
            {{ getErrorMessage(v$.form.imageSrc) }}
          </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 my-2">
          <label for="">الاسم</label>

          <div class="input">
            <input
      
              type="text"
              placeholder="الاسم"
              v-model="form.name"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.name.$error">
            {{ getErrorMessage(v$.form.name) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> الوصف </label>
          <div class="input">
            <textarea
              name=""
              id=""
              placeholder="الوصف"
              v-model="form.description"
              cols="30"
              rows="10"
              class="w-100"
   
            ></textarea>
          </div>
          <span class="error-feedback" v-if="v$.form.description.$error">
            {{ getErrorMessage(v$.form.description) }}
          </span>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useOpinionClientStore } from "@/stores/opinionClient/opinionClientStoreAdd";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";


export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useOpinionClientStore(),
      v$: useVuelidate(),
      form: {
        name: "",
        description: "",
        image: null,
        imageSrc: "",
      },
    };
  },

  validations() {
    return {
      form: {
        name: { required },
        description: { required },
        imageSrc: { required },
      },
    };
  },

  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Check if the file type is allowed

        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      this.store.addOpinionClient(this.form);
    },
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
input#vehicle1 {
  width: 5%;
}
</style>
