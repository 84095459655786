import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useWhowweStore = defineStore("whowe", {
  state: () => ({
    whowe: [],
    type:"",
  }),
  actions: {
    async fetchWhowe() {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      try {
        const response = await axios.post(
          "organization_fetch_subheaders"
        );

        if (response.data.status === true) {
          this.whowe = response.data.data;
          this.type = this.whowe.map((who) => who.type);
          // console.log(this.whowe, "whowe");
          // console.log(this.type, "dddddddddddddddd");
        }
      } catch (error) {
        // console.error("Error fetching courses:", error);
      } finally {
        loadingStore.stopLoading();
      }
    },
    async deleteWhowe(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("organization_delete_subheader", { id });
          this.whowe = this.whowe.filter((who) => who.id !== id);
          Swal.fire("تم الحذف!", "تم حذف المادة", "success");
        }
      } catch (error) {
        console.error("Error deleting course:", error);
        Swal.fire("Error!", "There was an error deleting the who.", "error");
      }
    },
  },
});
