<template>
  <header-pages title="الحضور"></header-pages>
  <div class="attend">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>id</th>
            <th>اسم</th>
            <th>اسم الجروب</th>
            <th>اسم الحصه</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="view in attend" :key="view.user_id">
            <td>{{ view.user_id }}</td>
            <td>{{ view.user_name }}</td>
            <td>{{ view.group_name }}</td>
            <td>{{ view.session_name }}</td>
          </tr>
        </tbody>
      </table>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationTotalPages"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import axios from "axios";

export default {
  components: {
    HeaderPages,
    PaginationPage,
  },
  data() {
    return {
      attend: [],
    };
  },
  methods: {
    async Attendanceabsence(page = 1) {
      try {
        const response = await axios.post(`fetch_attendance?page=${page}`);
        if (response.data.status) {
          this.attend = response.data.data.data; // Ensure you access the correct path in your response

          // Update pagination store (optional)
          const paginationStore = usePaginationStore();
          paginationStore.current_page = response.data.meta.current_page;
          paginationStore.last_page = response.data.meta.last_page;
          paginationStore.total = response.data.meta.total;
        } else {
          console.error("Error fetching attendance:", response.data.message);
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
    handlePageChange(page) {
      this.Attendanceabsence(page); // Fetch attendance for the selected page
    },
  },
  computed: {
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationLast: (state) => state.last_page,
      paginationTotal: (state) => state.total,
      paginationPer: (state) => state.per_page,
    }),
    paginationTotalPages() {
      // Calculate the total number of pages
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.Attendanceabsence(this.paginationCurrent); // Fetch attendance for the initial page
  },
};
</script>
