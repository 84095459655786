<template>
  <div class="slider">
    <header-pages
      title="اسليدر"
      button="اضافة  اسليدر "
      link="/add-slider"
    ></header-pages>

    <div class="alll">
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteGroup"
    />
  </div>
</div>

</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: ["الصوره", "	العنوان"],
      editLink: "/edit-group",
      viewLink: "/view-group",
    };
  },
};
</script>
