import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import { isArray } from "chart.js/helpers";


export const useCompetitionEditStore = defineStore("competitionEdit", {
  state: () => ({
    form: [],
    loading: false,
  }),
  actions: {
    async getCompetition(id , ) {
      try {
        const response = await axios.post("fetch_competition_details", {
          id: id,
        });
        if (response.data.status === true) {

        this.form  = response.data.data;
        // console.log(this.form , "jjjjjjjjjjjjjjjj");
      
        } else {
          throw new Error("Failed to fetch groups data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateCompetition(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "image") {
            if (data[key] instanceof File) {
              formData.append("image", data[key]);
            }
          } else if (isArray(data[key])) {
            data[key].forEach((item, index) => {
              Object.keys(item).forEach((subKey) => {
                formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
              });
            });
          } else {
            formData.append(key, data[key]);
          }
        });
        const response = await axios.post("edit_competition", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.competition = response.data.data;
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Competition Updated Successfully",
          });

          await router.replace("/index-competion");

        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
  },
});
