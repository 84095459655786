<template>
  <LayOutVue />
</template>
<script>
import LayOutVue from "@/components/LayOutStractuer/LayOut.vue";
export default {
  name: "dashboard-page",
  components: {
    LayOutVue,
  },
};
</script>
