<template>
  <div class="add_teacher">
    <HeaderPages title=" إضافة قسم جديد" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="col-lg-6 col-md-6 col-sm-12 my-2">
        <label for="">الاسم</label>

        <div class="input">
          <input
            required
            type="text"
            placeholder="الاسم"
            v-model="form.title"
          />
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";

import { usecategoryAddStore } from "@/stores/category/categoryStoreAdd";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
  },
  data() {
    return {
      store: usecategoryAddStore(),
      form: {
        title: "",
      },
    };
  },

  methods: {
    submitForm() {
      this.store.addCategory(this.form);
    },
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
input#vehicle1 {
  width: 5%;
}
</style>
