<template>
  <div class="courses">
    <header-pages button="اضافة اشتراك" link="/add-subscription" title="الاشتراكات"/>

    <div class="alll">
    <tables-page-vue
        :editLink="editLink"
        :headers="tableHeaders"
        :pages="tablePages"
        :rows="tableRows"
        :showSelect="false"
        :viewLink="viewLink"
        @delete="handleDeleteSubscription"
    />
    <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
    />
  </div>
</div>

</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import {mapState} from "pinia";
import {useSubscriptionStore} from "@/stores/subscription/subscriptionStore";
import {usePaginationStore} from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";

export default {
  components: {HeaderPages, TablesPageVue, PaginationPage},
  data() {
    return {
      tableHeaders: ["ID", "	الكورسات", "	الجروبات", "	الطلاب"],
      editLink: "edit-subscription",
      viewLink: "view-subscription",
    }
  },

  computed: {
    ...mapState(useSubscriptionStore, {
      subscriptions: (state) => state.subscriptions,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      return this.subscriptions.map((part) => [
        part.id,
        part.course?.name ?? "غير موجود",
        part.group?.title ?? "غير موجود",
        part.user.name ?? "غير موجود",

      ]);
    },
    tablePages() {
      return Array.from({length: this.paginationLast}, (_, i) => i + 1);
    },
  },
  methods: {

    handlePageChange(page) {
      const paginationStore = usePaginationStore();
      paginationStore.setCurrentPage(page);
    },
    handleDeleteSubscription(id) {
      const subscriptionStore = useSubscriptionStore();
      subscriptionStore.deletesubscriptions(id);
    },
  },
  mounted() {
    const subscriptionStore = useSubscriptionStore();
    subscriptionStore.getSubscriptions();
  },

}
</script>

<style>

</style>