import { defineStore } from "pinia";
import Swal from "sweetalert2";
import axios from "axios";

export const useScreenStore = defineStore("screen", {
  state: () => ({
    screens: [],
  }),
  actions: {
    async getScreen() {
      try {
        const response = await axios.post("fetch_screens");
        if (response.data.status === true) {
          this.screens = response.data.data;
          console.log(this.screens);
        } else {
          console.log("Error fetching screens");
        }
      } catch (error) {
        console.error("Error fetching screens:", error);
      }
    },
    async deleteScreen(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("delete_screen", { id });
          this.screens = this.screens.filter((screens) => screens.id !== id);
          Swal.fire("تم الحذف!", "تم حذف الاسكرين", "success");
        }
      } catch (error) {
        console.error("Error deleting screen:", error);
        Swal.fire("Error!", "There was an error deleting the header.", "error");
      }
    },
  },
});
