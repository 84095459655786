import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useTeacherAddStore = defineStore("teacherAdd", {
  state: () => ({
    teachers: [],
    curriculums: [],
    jobTypes: [],
  }),
  actions: {
    async getCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        this.curriculums = response.data.data;
      } catch (error) {
        console.error("Error fetching curriculums:", error);
      }
    },

    async getJobTypes() {
      try {
        const response = await axios.post("fetch_job_types");
        this.jobTypes = response.data.data.data;
        console.log(this.jobTypes, "jobTypes");
      } catch (error) {
        console.error("Error fetching job types:", error);
      }
    },

    async addTeacher(data) {
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key]) && key !== "certificateImagesSrc") {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (key !== "imageSrc" && key !== "certificateImagesSrc") {
            formData.append(key, data[key]);
          }
        });

        // Send FormData to the API
        const response = await axios.post("add_employee", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          
          Swal.fire("Success", "Teacher added successfully", "success");
          router.push("teacher-index");
          return response.data;
        } else {
          throw new Error(response.data.message || "Failed to add the teacher");
        }
      } catch (error) {
        Swal.fire("Error", error.response.data.message, "error");
        console.error("Error adding teacher:", error);
      }
    },
  },
});
