import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import swal from "sweetalert2";

export const useExamsAddStore = defineStore("examsAdd", {
  state: () => ({
    exams: [],
    groups: [],
  }),
  actions: {
    async addExam(examsData) {
      try {
        // console.log(examsData, "examsData");
        // console.log(examsData.group_ids, "examsData.group_ids");
        const formData = new FormData();

        // Append each property to FormData
        Object.keys(examsData).forEach((key) => {
          const value = examsData[key];

          if (key === "questions" && Array.isArray(value)) {
            // Loop through questions array
            value.forEach((question, questionIndex) => {
              Object.keys(question).forEach((questionKey) => {
                if (questionKey === "type") {
                  // Append question_type
                  formData.append(`questions[${questionIndex}][${questionKey}]`, question[questionKey].id);
                } else if (questionKey === "answers" && Array.isArray(question[questionKey])) {
                  // Loop through answers array within each question
                  question.answers.forEach((answer, answerIndex) => {
                    Object.keys(answer).forEach((answerKey) => {
                      if (answerKey === "is_correct") {
                        // Append boolean as 1 or 0
                        formData.append(`questions[${questionIndex}][answers][${answerIndex}][${answerKey}]`, answer[answerKey] ? 1 : 0);
                      } else {
                        // Append other answer properties
                        formData.append(`questions[${questionIndex}][answers][${answerIndex}][${answerKey}]`, answer[answerKey]);
                      }
                    });
                  });
                } else {
                  // Append other question properties
                  formData.append(`questions[${questionIndex}][${questionKey}]`, question[questionKey]);
                }
              });
            });
          } else if (key === "group_ids" && Array.isArray(value)) {
            // Loop through group_ids array
            value.forEach((groupId, groupIndex) => {
              formData.append(`group_ids[${groupIndex}]`, groupId);
            });
          } else {
            // Append non-array values normally
            formData.append(key, value);
          }
        });

        // Make the API request
        const response = await axios.post("add_exam", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Log response data
        console.log("API Response:", response.data);

        if (response.data.status) {
          Swal.fire(
            "Success",
            "Steps have been saved successfully.",
            "success"
          );
          await router.replace("/exams");
        } else {
          Swal.fire(
            "Error",
            "There was a problem saving the steps: " + response.data.message,
            "error"
          );
        }
      } catch (error) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });      }
    },

    async fetchgroup() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status) {
          this.groups = response.data.data;
          // console.log(this.groups);
        } else {
          throw new Error("Failed to fetch groups data");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
