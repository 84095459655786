import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useGroupsAddStore = defineStore("groupsAdd", {
  state: () => ({
    groups: [],
    courses: [],
    teachers: [],
    courseContents: [],
    disabilities: [],
    days: [],
    Disipilty_value: [],
  }),
  actions: {
    async fetchCourses() {
      const response = await axios.post("fetch_courses");
      if (response.data.status === true) {
        this.courses = response.data.data.data;
        // console.log(response.data.data.data, "courses");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchTeachers() {
      const response = await axios.post("fetch_teachers");
      if (response.data.status === true) {
        this.teachers = response.data.data.data;
        // console.log(response.data.data.data, "teachers");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchDisabilities() {
      const response = await axios.post("organization_fetch_disabilities");

      if (response.data.status) {
        console.log("Fetched Disabilities:", response.data.data); // Log fetched data
        this.disabilities = response.data.data;
        this.Disipilty_value = this.disabilities.map((disability) => ({
          id: disability.id,
          title: disability.title,
        }));
        if (response.data.status === true) {
          this.disabilities = response.data.data;
          // console.log(response.data.data, "disabilities");
        } else {
          console.log("Error fetching disabilities.");
        }
      }
    },
    async fetchDays() {
      const response = await axios.post("fetch_days");
      if (response.data.status === true) {
        this.days = response.data.data;
        // console.log(response.data.data, "days");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchCourseContent() {
      const response = await axios.post("organization_fetch_stages");
      if (response.data.status === true) {
        this.courseContents = response.data.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async addGroups(groupData) {
      try {
        // // Log FormData entries
        // for (const [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`, "FormData");
        //   console.log(key, "sasa");
        // }

        // Make the API request
        const response = await axios.post("add_group", groupData, {});

        // Log response data
        console.log("API Response:", response.data);

        if (response.data.status) {
          // this.groups.push(response.data.data);
          Swal.fire(
            "Success",
            "Steps have been saved successfully.",
            "success"
          );
        } else {
          throw new Error("Failed to add group");
        }
        await router.push("/groups");
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
    async fetchGroup(id) {
      const response = await axios.post("fetch_group", { id: id });
      if (response.data.status === true) {
        this.group = response.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },
  },
});
