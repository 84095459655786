import { defineStore } from "pinia";
import axios from "axios";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useExamResultStore = defineStore("ExamResult", {
  state: () => ({
    examResults: [],
  }),
  actions: {
    async fetchExamResults() {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      useLoadingStore.loading = true;
      try {
        const response = await axios.post("fetch_exam_results");

        if (response.data.status) {
          this.examResults = response.data.data.data;
          // console.log(this.examResults);
        }
      } catch (error) {
        console.log(error);
      } finally {
        useLoadingStore.loading = false;
      }
    },
  },
});
