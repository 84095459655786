<template>
  <div class="add-moderators">
    <HeaderPages title="اضافة مشرف جديد" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">اضافه صوره </label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> المجموعه </label>
          <multiselect
            v-model="group"
            :options="postionOptions"
            :close-on-select="false"
            track-by="id"
            @update:modelValue="updatePostion"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اسم المشرف </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder=" اكتب اسم المشرف كاملا "
              v-model="form.moderators_name"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> رقم الجوال</label>
          <div class="input">
            <input
              type="tel"
              id="name"
              placeholder="+966"
              v-model="form.moderators_number"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input">
            <input
              type="password"
              id="name"
              placeholder=" ********"
              v-model="form.password"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تأكيد كلمة المرور </label>
          <div class="input">
            <input
              type="password"
              id="name"
              placeholder=" ********"
              v-model="form.Confirm_password"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="checkbox">
            <form>
              <input
                class="checkbox-input"
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label for="vehicle1">يمكن للمشرف اضافة تقييم للطالب</label><br />
            </form>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="checkbox">
            <form>
              <input
                class="checkbox-input"
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label for="vehicle1">يمكن للمشرف إضافة واجبات و اختبارات</label
              ><br />
            </form>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="checkbox">
            <form>
              <input
                class="checkbox-input"
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label for="vehicle1">يمكن للمشرف التواصل مع ولى امر الطالب</label
              ><br />
            </form>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      form: {
        group: "",
        moderators_name: "",
        moderators_number: "",
        password: "",
        Confirm_password: "",
      },
      postionOptions: ["معلم", "موظف"],
      CurriculaOptions: [],
    };
  },

  methods: {
    // add img 1
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    // add img 1
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
.checkbox-input{
    width: 5%;
}
</style>
