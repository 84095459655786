<template>
  <div class="questions-bank">
    <HeaderPages title="تعديل سؤال" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الامتحان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم الامتحان"
              v-model="question_bank.question"
            />
            <p
              class="text-danger"
              v-for="error in v$.question_bank.question.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="">نوع السؤال</label>

            <multiselect
              v-model="question_bank.type"
              :options="ExamsOptions"
              :close-on-select="false"
              track-by="id"
              label="title"
              :hidden-select="true"
              :clear-on-select="true"
            ></multiselect>
            <p
              class="text-danger"
              v-for="error in v$.question_bank.type.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">درجة الامتحان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل درجة الامتحان"
              v-model="question_bank.degree"
            />
            <p
              class="text-danger"
              v-for="error in v$.question_bank.degree.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">المرحلة</label>
          <div class="input">
            <multiselect
              v-model="question_bank.curriculum"
              :options="curriculums"
              :close-on-select="false"
              track-by="id"
              label="title"
              :hidden-select="true"
              :clear-on-select="true"
            ></multiselect>
            <p
              class="text-danger"
              v-for="error in v$.question_bank.curriculum.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الموسم</label>
          <div class="input">
            <multiselect
              v-model="question_bank.season"
              :options="seasons ?? []"
              :close-on-select="false"
              track-by="id"
              label="title"
              :hidden-select="true"
              :clear-on-select="true"
            ></multiselect>
            <p
              class="text-danger"
              v-for="error in v$.question_bank.season.$errors"
              :key="error.$uid"
            >
              {{ error.$message }}
            </p>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <div class="input">
            <button type="submit" class="save">اضافة</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useQuestionBankEditStore } from "@/stores/question_bank/QuestionBankEdit";
import { mapActions, mapState } from "pinia";
import Multiselect from "vue-multiselect";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "QuestionBanksEdit",
  components: {
    Multiselect,
    HeaderPages,
  },
  data() {
    return {
      v$: useVuelidate(),
      ExamsOptions: [
        { id: 1, title: "صوتي" },
        { id: 2, title: "اختياري" },
        { id: 3, title: "توصيل" },
        { id: 4, title: "تصحيح" },
      ],
    };
  },
  validations() {
    return {
      question_bank: {
        question: { required },
        type: { required },
        degree: { required },
        curriculum: { required },
        season: { required },
      },
    };
  },
  methods: {
    ...mapActions(useQuestionBankEditStore, [
      "getCurriculums",
      "getSeasons",
      "getQuestion",
      "updateQuestion",
    ]),
    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      try {
        this.question_bank.curriculum_id = this.question_bank.curriculum.id;
        this.question_bank.season_id = this.question_bank.season.id;
        this.question_bank.type =
          this.question_bank.type.title ?? this.question_bank.type;
        // this.question_bank.degree = parseInt(this.question_bank.degree);
        this.updateQuestion(this.question_bank);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapState(useQuestionBankEditStore, [
      "curriculums",
      "seasons",
      "question_bank",
    ]),
  },
  mounted() {
    this.getCurriculums();
    this.getSeasons();
    this.getQuestion(this.$route.params.id);
  },
};
</script>

<style scoped></style>
