<template>
  <div class="add-serves container">
    <HeaderPages title="إضافة خدمات مميزه جديدة" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">اضافة صورة</label>
            <input
              type="file"
              ref="fileInput"
              @change="handleFileChange"
              accept="image/*"
              style="display: none"
            />
            <div
              v-if="!data.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="data.imageSrc" class="avatar-preview">
              <img :src="data.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-md-12 col-12">
          <label for="name"> الاسم</label>
          <div class="input">
            <input
              type="text"
              v-model="data.title"
              id="name"
              placeholder="اكتب هنا "
              required
            />
          </div>
        </div>

        <div class="col-lg-7 col-md-12 col-12">
          <label for="description"> الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea
              id="description"
              v-model="data.sub_title"
              required
            ></textarea>
          </div>
        </div>

        <div
          class="col-12 my-3 border-bottom py-3"
          v-for="(feature, index) in data.features"
          :key="index"
        >
          <div class="d-flex gap-4">
            <div class="w-50">
              <label for="feature-name">اسم الميزه</label>
              <div class="input">
                <input
                  type="text"
                  v-model="feature.title"
                  placeholder="اكتب هنا"
                  required
                />
              </div>
            </div>
            <div class="w-25">
              <label for="feature-color">اللون</label>
              <div class="input">
                <div class="card flex justify-center">
                  <ColorPicker v-model="feature.color" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-between gap-4">
            <div class="w-full">
              <label for="description">وصف الميزه</label>
              <div class="input-textarea">
                <textarea v-model="feature.description" required></textarea>
              </div>
            </div>

            <div class="w-full">
              <div class="avatar-uploader">
                <label for="feature-image">اضافة صورة</label>
                <input
                  type="file"
                  :ref="'featureFileInput' + index"
                  @change="handleFeatureFileChange($event, index)"
                  accept="image/*"
                  style="display: none"
                />
                <div
                  v-if="!feature.imageSrc"
                  class="upload-icon"
                  @click="triggerFeatureFileInput(index)"
                >
                  <i class="fa fa-camera"></i>
                  <span>اختيار صورة</span>
                </div>
                <div v-if="feature.imageSrc" class="avatar-preview">
                  <img :src="feature.imageSrc" alt="Feature Image Preview" />
                  <i
                    class="fa fa-times delete-icon"
                    @click="removeFeatureImage(index)"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-between my-3 gap-3">
            <div
              class="btn btn-danger text-light"
              @click="removeFeature(index)"
              v-if="data.features.length > 1 && index !== 0"
            >
              <i class="fa fa-minus text-light"></i>
            </div>

            <div class="btn btn-success text-light" @click="addFeature">
              <i class="fa fa-plus text-light"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import ColorPicker from "primevue/colorpicker";
import { useFeatureServicesStoreedit } from "@/stores/featureServices/featureServicesStoreEdit";

export default {
  name: "ServicesAdd",
  components: {
    HeaderPages,
    ColorPicker,
  },
  data() {
    return {
      store: useFeatureServicesStoreedit(),
      data: {
        imageSrc: "",
        image: null,
        title: "",
        sub_title: "",
        features: [
          {
            title: "",
            description: "",
            image: null,
            imageSrc: "",
            color: "#000",
          },
        ],
      },
    };
  },
  methods: {
    addFeature() {
      this.data.features.push({
        title: "",
        description: "",
        image: null,
        imageSrc: "",
        color: "",
      });
    },
    removeFeature(index) {
      this.data.features.splice(index, 1);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.data.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.data.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.data.image = null;
      this.data.imageSrc = "";
    },
    handleFeatureFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.data.features[index].imageSrc = e.target.result;
          this.data.features[index].image = file;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFeatureFileInput(index) {
      // Access the dynamic reference for the specific file input in the loop
      this.$refs[`featureFileInput${index}`][0].click();
    },
    removeFeatureImage(index) {
      this.data.features[index].image = null;
      this.data.features[index].imageSrc = "";
    },
    submitForm() {
      this.store.editServicesStore(this.data);
    },
  },
  async mounted() {
    await this.store.getServicesStore(this.$route.params.id);
    this.data = this.store.ServicesStore;
    this.data.imageSrc = this.store.ServicesStore.image;

    this.data.features.forEach((item, index) => {
      this.data.features[index].imageSrc = item.image;
    });

    if (this.data.features.length === 0) {
      this.addFeature();
    }
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
.input-textarea {
  width: 100%;
}
.input-textarea textarea {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid var(--main);
}
</style>
