<template>
  <div class="edit-blog">
    <HeaderPages title="تعديل المدونه" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label> اضافة صوره للمدونه</label>
          <input
            type="file"
            id="avatar"
            @change="handleFileChange"
            accept="image/*"
            ref="fileInput"
            style="display: none"
          />
          <div v-if="!blog.image" class="upload-icon" @click="triggerFileInput">
            <i class="fa fa-camera"></i>
            <span>اختيار صورة</span>
          </div>
          <div v-if="image" class="avatar-preview">
            <img :src="image" alt="Avatar Preview" />
            <i class="fa fa-times delete-icon" @click="removeImage"></i>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> العنوان </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder=" اكتب هنا "
              v-model="blog.title"
            />
            <span v-if="v$.blog.title.$error"
              >{{ v$.blog.title.$errors[0].$message }}<></span
            >
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label> القسم</label>
          <multiselect
            v-model="blog.blog_categories"
            :options="blogsCategory"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
          <span v-if="v$.blog.blog_categories.$error" class="error-feedback"
            >{{ v$.blog.blog_categories.$errors[0].$message }}</span
          >
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label> الهشتاج</label>
          <multiselect
            v-model="blog.blog_hashtags"
            :options="blogsHashtag"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
          <span v-if="v$.blog.blog_hashtags.$error"
            >{{ v$.blog.blog_hashtags.$errors[0].$message }}<></span
          >
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <label for="name"> الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea
              id="w3review"
              name="w3review"
              v-model="blog.description"
            ></textarea>
            <span v-if="v$.blog.description.$error"
              >{{ v$.blog.description.$errors[0].$message }}<></span
            >
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions, mapState } from "pinia";
import { useBlogEdit } from "@/stores/blogs/BlogEditStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  computed: {
    ...mapState(useBlogEdit, ["blog", "blogsHashtag", "blogsCategory"]),
  },
  data() {
    return {
      v$: useVuelidate(),
      image: "",
    };
  },

  validations() {
    return {
      blog: {
        title: { required },
        blog_categories: { required },
        blog_hashtags: { required },
        description: { required },
      },
    };
  },

  methods: {
    ...mapActions(useBlogEdit, [
      "getBlogsCategory",
      "getBlogsHashtag",
      "updateBlog",
      "getBlog",
    ]),
    removeImage() {
      this.blog.image = null;
      this.image = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.blog.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    removeCertificate(index) {
      this.form.certificate_images.splice(index, 1);
      this.form.certificateImagesSrc.splice(index, 1);
    },
    submitForm() {
      this.blog.blog_hashtags = this.blog.blog_hashtags.map((item) => item.id);
      this.blog.blog_categories = this.blog.blog_categories.map(
        (item) => item.id
      );

      this.v$.$validate();
      if (this.v$.$error) return;
      this.updateBlog(this.blog);
    },
  },
  async mounted() {
    const fetchBlog = await this.getBlog(this.$route.params.id);
    const fetchBlogHashtag = await this.getBlogsHashtag();
    const fetchBlogCategory = await this.getBlogsCategory();
    await Promise.all([fetchBlog, fetchBlogHashtag, fetchBlogCategory]);
    this.image = this.blog?.image;
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
input#vehicle1 {
  width: 5%;
}
textarea {
  width: 100%;
  height: 100px;
  border: 1px solid var(--main);
  border-radius: 10px;
}
</style>
