import { defineStore } from "pinia";
import axios from "axios";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useExamResultShow = defineStore("ExamResultShow", {
  state: () => ({
    examResult: {},
  }),
  actions: {
    async getExamResult(id) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(`show_exam_result_answers`, {
          exam_result_id: id, // exam_result_id
        });
        this.examResult = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        loadingStore.stopLoading();
      }
    },
  },
});
