<template>
  <div class="Groups-add">
    <header-pages :showButton="false" link="/edit-group" title="تعديل مجموعة" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المجموعة</label>
          <div class="input">
            <input
              v-model="group.title"
              placeholder="أدخل أسم المجموعة"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">اختر المدرس</label>
          <multiselect
            v-model="group.teacher"
            :options="teachers"
            label="name"
            track-by="id"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ البدايه </label>
            <input v-model="group.start_date" placeholder="من  " type="date" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ النهايه </label>
            <input v-model="group.end_date" placeholder="الي  " type="date" />
          </div>
        </div>
   
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">أختر الكورس</label>
          <multiselect
            v-model="group.course"
            :close-on-select="true"
            :options="courses"
            label="name"
            track-by="id"
          ></multiselect>
          <!--          {{ group.course_value }}-->
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input v-model="group.allCourses" type="checkbox" value="all" />
            <label for="name">محتوي الكورس كامل</label>
          </div>
          <multiselect
            v-if="!group.allCourses"
            v-model="group.stages"
            :disabled="allCourses"
            :multiple="true"
            :options="courseContents"
            label="title"
            placeholder="أختار محتوي الكورس"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input
              v-if="group.with_all_disability == 0"
              type="checkbox"
              :checked="group.with_all_disability == 0"
              name="with_all_disability"
              @input="toggleDisabilitySelect"
            />
            <input
              v-else
              type="checkbox"
              :value="0"
              name="with_all_disability"
              v-model="group.with_all_disability"
            />
            <label for="with_all_disability">جميع الاعاقات</label>
          </div>

          <multiselect
            v-if="group.with_all_disability != 0"
            v-model="group.disabilities"
            :multiple="true"
            :options="disabilities"
            label="title"
            placeholder="أختر الاعاقات"
            track-by="id"
          ></multiselect>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <div class="col-lg-12 col-md-12 col-12">
            <div v-for="(day, index) in days" :key="index" class="week">
              <div class="chek">
                <div class="input">
                  <!-- Bind the checkbox to the selected state of each day -->
                  <input
                    v-model="day.selected"
                    :checked="isSelected(day)"
                    type="checkbox"
                  />
                </div>
                <!-- Display the day name dynamically -->
                <label :for="day.title">{{ day.title }}</label>
              </div>

              <!-- Only show the time inputs if the day is selected -->
              <div v-if="day.selected" class="times">
                <div class="input">
                  <label for="time_start">من</label>
                  <!-- Bind each day's time_start value -->
                  <input v-model="day.start_time" type="time" />
                </div>
                <div class="input">
                  <label for="time_end">إلى</label>
                  <!-- Bind each day's time_end value -->
                  <input v-model="day.end_time" type="time" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions, mapState } from "pinia";
import { useGroupsEditStore } from "@/stores/Groups/GroupsEditStore";

export default {
  name: "GroupsAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      selectedDay: null,
      allDisabilities: false,
      allCourses: false,
      CoursesOptions: [
        {
          id: 1,
          title: "Course 1",
        },
        {
          id: 2,
          title: "Course 2",
        },
        {
          id: 3,
          title: "Course 3",
        },
      ],
      Courses_value: [],
      Disipilty_value: [],
      DisiplityOptions: [
        {
          id: 1,
          title: "Disipilty 1",
        },
        {
          id: 2,
          title: "Disipilty 2",
        },
        {
          id: 3,
          title: "Disipilty 3",
        },
      ],
      TeatcherOptions: [
        {
          id: 1,
          title: "Teacher 1",
        },
        {
          id: 2,
          title: "Teacher 2",
        },
        {
          id: 3,
          title: "Teacher 3",
        },
      ],
      Teatcher_value: [],
      GroupsOptions: [
        {
          id: 1,
          title: "Group 1",
        },
        {
          id: 2,
          title: "Group 2",
        },
        {
          id: 3,
          title: "Group 3",
        },
      ],
      Groups_value: [],
    };
  },
  computed: {
    ...mapState(useGroupsEditStore, [
      "courseContents",
      "disabilities",
      "courses",
      "days",
      "teachers",
      "group",
    ]),
  },
  // watch: {
  //   group: {
  //     deep: true,
  //     handler(newValue) {
  //       if (!newValue) return;
  //       this.toggleDisabilitySelect();
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    ...mapActions(useGroupsEditStore, [
      "fetchCourseContent",
      "fetchDisabilities",
      "fetchCourses",
      "fetchTeachers",
      "fetchDays",
      "updateGroup",
      "fetchGroupDetails",
    ]),
    isSelected(day) {
      if (this.group?.days?.some((d) => d.id === day.id)) {
        return (day.selected = true);
      } else {
        return (day.selected = false);
      }
    },
    toggleDisabilitySelect() {
      if (this.group?.with_all_disability == 0) {
        this.group.with_all_disability = 1;
      } else {
        this.group.with_all_disability = 0;
      }
    },
    submitForm() {
      // Construct the days array in the required format
      const selectedDays = this.days
        .filter((day) => day.selected)
        .map((day) => ({
          day_id: day.id,
          start_time: day.start_time ?? day.time_start,
          end_time: day.end_time ?? day.time_end,
        }));
      // Assign the selected days in the format: days[0][start_time], days[0][end_time], days[0][day_id]
      this.group.days = selectedDays;
      // Handling allCourses and allDisabilities as boolean-to-integer
      this.group.with_all_course_content = this.group.allCourses ? 1 : 0;
      // console.log(this.group.with_all_disability)
      this.group.with_all_disability = this.group.with_all_disability ? 1 : 0;
      this.group.disabilities =
        this.group.disabilities?.map((item) => item.id) || [];
      // Ensure multiselect values are set to their corresponding ids
      this.group.stages = this.group.stages.map((item) => item.id) || null;
      this.group.course_id = this.group.course.id || null;
      this.group.Disipilty_value = null;
      this.group.teacher_id = this.group.teacher?.id || null;
      this.group.Teatcher_value = null;
      this.group.course_value = null;
      this.group.stage_value = null;
      this.group.allCourses = null;
      this.group.allDisabilities = null;
      this.updateGroup(this.group);
    },
  },
  mounted() {
    this.fetchCourseContent();
    this.fetchDisabilities();
    this.fetchCourses();
    this.fetchTeachers();
    this.fetchDays();
    this.fetchGroupDetails(this.$route.params.id);
  },
};
</script>
<style scoped>
#all {
  display: flex;
  margin-top: 15px;
}

.dayes {
  margin-right: 34px;
}

textarea {
  width: 100%;
  height: 70px;
  border: 1px solid #06797e;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: none;
  color: #a6a6a6;
}

#start {
  width: 280px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid #06797e;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

#end {
  width: 280px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid #06797e;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

label {
  margin-top: 8px;
}
</style>
