<template>
  <div class="edit-Department">
    <HeaderPages title="  تعديل القسم" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-12">
          <label for="name"> اسم القسم </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب اسم القسم  "
              v-model="blogCategory.title"
            />
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useUpdateBlogCategoryStore } from "@/stores/BlogCategory/BlogCategoryEditStore";
import { mapActions, mapState } from "pinia";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  computed: {
    ...mapState(useUpdateBlogCategoryStore, ["blogCategory"]),
  },
  methods: {
    ...mapActions(useUpdateBlogCategoryStore, [
      "getUpdateBlogCategory",
      "UpdateBlogCategory",
    ]),
    submitForm() {
      this.UpdateBlogCategory(this.blogCategory);
    },
  },
  async mounted() {
    await this.getUpdateBlogCategory(this.$route.params.id);
  },
};
</script>
