<template>
  <div v-if="showfooter" class="main-footer">
    <footer class="all-footer">
      <h4>بدعم من اوقاف الضحيان</h4>
      <img src="@/assets/photos/logo-preview.png" alt="" />
      <h4>Made with by <span>T</span> ECH LAB</h4>
    </footer>
  </div>
</template>
<script>
export default {
  name: "FooterBar",
computed: {
  showfooter(){
    return this.$route.path !=="/live-teacher"
  }
}
};
</script>
