<template>
  <div class="successpartners">
    <header-pages
      title="شركاء النجاح"
      button="+ اضافة شريك جديد "
      link="/add-successpartners"
    ></header-pages>

    <div class="alll">
    <tables-page-vue
      :editLink="editLink"
      :headers="tableHeaders"
      :pages="tablePages"
      :rows="tableRows"
      :showSelect="false"
      :viewLink="viewLink"
      @delete="handleDeleteGroup"
    />
  </div>

  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { useSuccesspartnersStore } from "@/stores/Successpartners/SuccesspartnersStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";

export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: ["ID", "	صوره", "	الوصف", "links"],
      editLink: "edit-successpartners",
      viewLink: "/view-group",
    };
  },
  computed: {
    ...mapState(useSuccesspartnersStore, {
      successpartners: (state) => state.successpartners,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      const rows = this.successpartners.map((part) => [
        part.id,
        part.image,
        part.title,
        {
          // text: part.link,
          to: part.link,
        },
      ]);
      console.log(rows, "nasraaaaaa"); // تحقق من مخرجات البيانات
      return rows;
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const paginationStore = usePaginationStore();
      paginationStore.setCurrentPage(page);
    },
    handleDeleteGroup(id) {
      const partnerStore = useSuccesspartnersStore();
      partnerStore.deletePartners(id);
    },
  },
  mounted() {
    const successpartnersStore = useSuccesspartnersStore();
    successpartnersStore.getSuccesspartners();
    console.log(this.tableRows, "ssss");
  },
};
</script>
