import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useCompetitionStore = defineStore("competitions", {
  state: () => ({
    competitions: [],
  }),
  actions: {
    async fetchCompetitions(page = 1 ,word="") {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(`fetch_competitions?page=${page}`, {
          word: word,
        });
        const paginationStore = usePaginationStore();
        const { current_page, from, last_page, per_page, to, total } =
          response.data.data.meta;

        if (response.data.status) {
          this.competitions = response.data.data.data;
          // console.log(this.competitions, "Employees List");

          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
        }
      } catch (error) {
        console.error("Error fetching competitions:", error);
      } finally {
        loadingStore.stopLoading();
      }
    },

    async deleteCompetitions(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });

        if (result.isConfirmed) {
          await axios.post("delete_competition", { id });
          this.competitions = this.competitions.filter((emp) => emp.id !== id);
          Swal.fire("تم الحذف!", "تم حذف الأمتحان", "success");
        }
      } catch (error) {
        console.error("Error deleting exam:", error);
        Swal.fire(
          "Error!",
          "There was an error deleting the employee.",
          "error"
        );
      }
    },
    filterCompetitions(word) {
      if (word === "") {
        return this.competitions; // Return all Curriculas if no search word
      } else {
        return this.competitions.filter(
          (dis) =>
            dis.name.toLowerCase().includes(word.toLowerCase()) || // Search by name
            dis.image.toLowerCase().includes(word.toLowerCase()) || // Search by email
            dis.description.includes(word) // Search by phone number
        );
      }
    },
  },
});
