<template>
  <div class="courses-add">
    <header-pages
      :showButton="false"
      link="/edit-courses"
      title="اضافة الدوره"
    />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الدورة</label>
          <div class="input">
            <input
              v-model="Courses.name"
              placeholder="أدخل أسم الدورة"
              type="text"
            />
            <span class="error-feedback" v-if="v$.Courses.name.$error">
              {{ getErrorMessage(v$.Courses.name) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">السنوات الدراسيه</label>
          <multiselect
            v-model="Courses.year_id"
            :close-on-select="true"
            :multiple="false"
            :options="yearsOptions"
            label="title"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.Courses.year_id.$error">
            {{ getErrorMessage(v$.Courses.year_id) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Disapilty">الاعاقات</label>
          `
          <multiselect
            v-model="Courses.disability_ids"
            :close-on-select="true"
            :multiple="true"
            :options="DisapilityOptions"
            label="title"
            track-by="id"
          ></multiselect
          >`
          <span class="error-feedback" v-if="v$.Courses.disability_ids.$error">
            {{ getErrorMessage(v$.Courses.disability_ids) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">الفصول الدراسيه</label>
          <multiselect
            v-model="Courses.season_id"
            :close-on-select="false"
            :multiple="false"
            :options="seasonOptions"
            label="title"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.Courses.season_id.$error">
            {{ getErrorMessage(v$.Courses.season_id) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">المناهج</label>
          <multiselect
            v-model="Courses.curriculum_id"
            :close-on-select="false"
            v-if="showCurriculums"
            :multiple="false"
            :options="curriculaOptions"
            label="title"
            track-by="id"
          ></multiselect>
          <!--          <span class="error-feedback" v-if="v$.Courses.curriculum_id.$error">-->
          <!--            {{ getErrorMessage(v$.Courses.curriculum_id) }}-->
          <!--          </span>-->
          <div style="display: flex; align-items: center">
            <input
              v-model="Courses.all_curriculum"
              type="checkbox"
              id="name"
              value="all"
              @change="changeAllCurriculum"
            />
            <label for="name">المنهج كامل</label>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">المراحل</label>
          <multiselect
            v-model="Courses.stage_ids"
            :close-on-select="false"
            :multiple="true"
            :options="stagesOptions"
            label="title"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.Courses.stage_ids.$error">
            {{ getErrorMessage(v$.Courses.stage_ids) }}
          </span>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useCoursesAddStore } from "@/stores/courses/CoursesAddStore";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CoursesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      store: useCoursesAddStore(),
      showCurriculums: true,
      Courses: {
        name: "",
        year_id: null,
        stage_ids: [],
        curriculum_id: null,
        disability_ids: [],
        season_id: null,
        all_curriculum: 1,
      },
      yearsOptions: [],
      stagesOptions: [],
      curriculaOptions: [],
      DisapilityOptions: [],
      seasonOptions: [],
    };
  },
  validations() {
    return {
      Courses: {
        name: { required },
        year_id: { required },
        stage_ids: { required },
        // curriculum_id: { required },
        disability_ids: { required },
        season_id: { required },
      },
    };
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    changeAllCurriculum() {
      this.showCurriculums = !this.showCurriculums;
    },
    // updateYear() {
    //   if (this.Courses.year_id) {
    //     this.Courses.year_id = this.Courses.year_id.id;
    //   }
    // },

    async getData() {
      await Promise.all([
        this.store.fetchYears(),
        this.store.fetchDisabilities(),
        this.store.fetchSeasons(),
        this.store.fetchStages(),
        this.store.fetchCurricula(),
      ]);
      this.yearsOptions = this.store.years || [];
      this.DisapilityOptions = this.store.disabilities || [];
      this.seasonOptions = this.store.seasonStudies || [];
      this.curriculaOptions = this.store.Curricula || [];
      this.stagesOptions = this.store.Stages || [];
    },
    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      this.Courses.year_id = this.Courses.year_id.id;
      this.Courses.disability_ids = this.Courses.disability_ids.map(
        (item) => item.id
      );
      this.Courses.season_id = this.Courses.season_id.id;
      this.Courses.stage_ids = this.Courses.stage_ids.map((item) => item.id);
      this.Courses.curriculum_id = this.Courses.curriculum_id?.id || null;
      this.Courses.all_curriculum = this.showCurriculums ? 1 : 0;
      this.store.addCourse(this.Courses);
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    "Courses.curriculum_id": function (newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped>
.all-btn {
  display: flex;
  gap: 10px;
  /* margin-top: 20px; */
}
</style>
