<template>
  <div class="Groups-add ">
    <header-pages title="اضافة  هيدر " :showButton="false" link="/add-header" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
               <span class="error-feedback" v-if="v$.form.imageSrc.$error">{{
              getErrorMessage(v$.form.imageSrc)
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12 mt-3">
          <label for="name">عنوان الهيدر</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="form.description "
            />
          </div>
          <span class="error-feedback" v-if="v$.form.description.$error">{{
              getErrorMessage(v$.form.description)
            }}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12 mt-3">
          <label for="name">عنوان الهيدر</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="form.title "
            />
            <span class="error-feedback" v-if="v$.form.title.$error">{{
              getErrorMessage(v$.form.title)
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12 mt-3">
          <label for="name">وصف الهيدر</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="form.subtitle"
            />

            <span class="error-feedback" v-if="v$.form.subtitle.$error">{{
              getErrorMessage(v$.form.subtitle)
            }}</span>
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save" @click="Save()">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useHeaderAddStore } from "@/stores/Header/HeaderAddStore";
import { mapState } from "pinia";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Swal from "sweetalert2";

export default {
    components: { HeaderPages },
    data(){

        return{
v$:useVuelidate(),
form:{
  image: null,
  imageSrc: "",
  description : "",
  subtitle: "",
  title: "",
}
        }
    },
    validations() {
    return {
      form: {
        imageSrc: { required },
        title: { required },
        subtitle: { required },
        description: { required },
      },
    };
  },
    computed: {
    ...mapState(useHeaderAddStore, {
      header: (state) => state.header,
    }),
  },
    methods:{

      getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
        async submitForm() {
     

      try {
        const HeaderStore = useHeaderAddStore();
        if (!HeaderStore) {
          throw new Error("Failed to load Courses store");

        }
        if(!this.form.imageSrc
          || !this.form.title
          || !this.form.subtitle
          || !this.form.description
        ){
          // Swal.fire("Error", "Please fill in all fields", "error");
          return;
        }
        await HeaderStore.addHeader(this.form);
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
        triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    Save() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
    }

}
</script>

<style>
.error-feedback {
  color: red;
  font-size: 0.85rem;
}
</style>