import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useAllServicesStore = defineStore("servicesStore", {
  state: () => ({
    ServicesStoree: [],
  }),
  actions: {
    async fetchFeatureServicesStore() {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(`organization_fetch_service_features`);

        if (response.data.status) {
          this.ServicesStoree = response.data.data;
        } else {
          console.error("Failed to fetch services:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
        Swal.fire("Error", "Unable to fetch services", "error");
      } finally {
        loadingStore.stopLoading();
      }
    },

    async deleteServicesStore(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("organization_delete_service_feature", { id });
          this.ServicesStore = this.ServicesStore.filter(
            (service) => service.id !== id
          );
          Swal.fire("تم الحذف!", "تم حذف المكتبه", "success");
        }
      } catch (error) {
        console.error("Error deleting service:", error);
        Swal.fire("Error", "There was an error deleting the service.", "error");
      }
    },
  },
});
