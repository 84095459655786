import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useCategoryeditStore = defineStore("Categoryedit", {
  state: () => {
    return {
      Category: {},
    };
  },
  actions: {
    async getCategory(id) {
      try {
        const response = await axios.post("fetch_library_category_details", {
          id: id,
        });
        this.Category = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async editCategory(data) {
      try {
        const formData = new FormData();
        console.log(data, "data");

        // Loop through data to populate FormData
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            // Handle arrays (if needed in the future)
          } else {
            // Skip imageSrc to avoid sending it
            if (key === "imageSrc") return;

            // Only append the file if it's an instance of File
            if (key === "file") {
              if (key instanceof File) {
                formData.append(key, data[key]);
              }
            } else {
              // Append other fields normally
              formData.append(key, data[key]);
            }
          }
        });

        // Send the FormData to the API
        const response = await axios.post("edit_library_category", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire("Success", "category has been updated.", "success");
          router.push("/index-category");
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error("Error editing Category:", error);
        swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
  },
});
