<template>
  <div class="add_teacher">
    <HeaderPages title=" إضافة معلم جديد" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <span class="error-feedback" v-if="v$.form.imageSrc.$error">
              {{ getErrorMessage(v$.form.imageSrc) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label>اضافة صورة الشهادادت الحاصل عليها</label>
          <input
            type="file"
            @change="handleCertificatesChange"
            accept="image/*"
            multiple
            ref="certificatesInput"
            style="display: none"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span> اختيار صوره</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(src, index) in form.certificateImagesSrc"
              :key="index"
              class="certificate-image"
            >
              <img :src="src" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificate(index)"
              ></i>
            </div>
            <span
              class="error-feedback"
              v-if="v$.form.certificateImagesSrc.$error"
            >
              {{ getErrorMessage(v$.form.certificateImagesSrc) }}
            </span>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الوظيفه</label>
          <multiselect
            v-model="form.select_job"
            :options="postionOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            select-label=""
            :hide-selected="true"
            :clear-on-select="true"
          ></multiselect>
        </div> -->
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اسم المعلم</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل  اسم المعلم"
              v-model="form.name"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.name.$error">
            {{ getErrorMessage(v$.form.name) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 my-2">
          <label for="">رقم الهاتف</label>

          <div class="input">
            <input type="فثم" placeholder="رقم الهاتف" v-model="form.phone" />
          </div>

          <span class="error-feedback" v-if="v$.form.phone.$error">
            {{ getErrorMessage(v$.form.phone) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> العمر </label>
          <div class="input">
            <input type="number" placeholder="العمر" v-model="form.age" />
          </div>
          <span class="error-feedback" v-if="v$.form.age.$error">
            {{ getErrorMessage(v$.form.age) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ الميلاد </label>
          <div class="input">
            <input
              type="date"
              placeholder="ادخل تاريخ الميلاد"
              v-model="form.date_of_birth"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.date_of_birth.$error">
            {{ getErrorMessage(v$.form.date_of_birth) }}
          </span>
        </div>

        

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> الوظيفه </label>
          <multiselect
            v-model="form.job_type"
            :options="jobsType"
            track-by="id"
            label="title"
          ></multiselect>
          <span class="error-feedback" v-if="v$.form.job_type.$error">
            {{ getErrorMessage(v$.form.job_type) }}
          </span>
        </div>

 
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> المناهج </label>
          <multiselect
            v-model="form.curriculum"
            :options="CurriculaOptions"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
          <span class="error-feedback" v-if="v$.form.curriculum.$error">
            {{ getErrorMessage(v$.form.curriculum) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكترونى </label>
          <div class="input">
            <input
              type="email"
              id="name"
              placeholder="البريد الالكترونى"
              v-model="form.email"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.email.$error">
            {{ getErrorMessage(v$.form.email) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input">
            <input
              type="password"
              id="name"
              placeholder="كلمة المرور"
              v-model="form.password"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.password.$error">
            {{ getErrorMessage(v$.form.password) }}
          </span>
        </div>

       <div class="col-lg-6 col-md-6 col-12">
        <div class="identitynumber">
            <label for=""> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="text"
                placeholder="ادخل الرقم الهويه"
                v-model="form.identity_number"
              />
            </div>
            <span class="error-feedback" v-if="v$.form.identity_number.$error">
              {{ getErrorMessage(v$.form.identity_number) }}
            </span>
          </div>

       </div>
        <div class="col-lg-2 col-md-6 col-12 mt-2">
          <!-- {{ form.gender }} -->
          <label for="gender">النوع</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.gender"
                value="1"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.gender"
                class="form-check-input"
                value="0"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                أنثي
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-12 mt-2">
          <label for="name">نوع الهوية</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.identity_type"
                value="0"
                type="radio"
                name="card"
                id="flexRadioDefault5"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.identity_type"
                class="form-check-input"
                value="1"
                type="radio"
                name="stay"
                id="flexRadioDefault6"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
         
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12 mt-2">
          <label for="" class=""> الحالة الاجتماعية </label>
          <div class="d-flex">
            <div class="form-check d-flex gap-2">
              <label class="form-check-label" for="flexRadioDefault7">
                متزوج
              </label>
              <input
                v-model="form.marital_status"
                class="form-check-input"
                value="1"
                type="radio"
                name="marital_status"
                id="flexRadioDefault7"
                checked
              />
            </div>
            <div class="form-check d-flex gap-2">
              <label class="form-check-label" for="flexRadioDefault8">
                اعزب
              </label>
              <input
                v-model="form.marital_status"
                class="form-check-input"
                value="0"
                type="radio"
                name="marital_status"
                id="flexRadioDefault8"
                checked
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="checkbox">
            <form>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label for="vehicle1"> اضافة المعلم الى قائمة المشرفين </label
              ><br />
     

            </form>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import useVuelidate from "@vuelidate/core";
import { useTeacherAddStore } from "@/stores/teacher/teacherStoreAdd";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      jobsType: [],

      maritals_status: [
        {
          id: 0,
          title: "اعزب",
        },
        {
          id: 1,
          title: "متزوج",
        },
      ],
      job_type_id: 1,
      store: useTeacherAddStore(),
      v$: useVuelidate(),
      form: {
        job_type_id: "",
        is_employed: 1,
        // select_job: "",
        name: "",
        date_of_birth: "",
        phone: "",
        gender: 0,
        identity_type: 1,
        identity_number: "",
        marital_status: 0,
        curriculum_ids: [],
        email: "",
        password: "",
        age: "",
        certificate_images: [], // Initialize the array
        certificateImagesSrc: [], // Initialize the array for image previews
        job_type: "",
        curriculum: "",
      },
      postionOptions: [
        {
          id: 1,
          title: "معلم",
        },
        {
          id: 2,
          title: "موظف",
        },
      ],
      CurriculaOptions: [],
    };
  },
  validations() {
    return {
      form: {
        imageSrc: { required },
        name: { required },
        date_of_birth: { required },
        gender: { required },
        identity_type: { required },
        identity_number: { required },
        marital_status: { required },
        curriculum: { required },
        email: { required },
        password: { required },
        age: { required },
        phone: { required },
        job_type: { required },
        certificateImagesSrc: { required },
      },
    };
  },

  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    removeCertificate(index) {
      this.form.certificate_images.splice(index, 1);
      this.form.certificateImagesSrc.splice(index, 1);
    },

    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      }
      this.form.job_type_id = this.form.job_type.id;
      this.form.curriculum_ids = this.form.curriculum.map((item) => {
        return item.id;
      });

      this.store.addTeacher(this.form);
    },
  },
  mounted() {
    this.store.getCurriculums().then(() => {
      this.CurriculaOptions = this.store.curriculums;
    });
    this.store.getJobTypes().then(() => {
      this.jobsType = this.store.jobTypes;
    });
  },
};
</script>
