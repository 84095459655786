<template>
  <div class="employees-add">
    <HeaderPages :showButton="false" title="تعديل موظف" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              id="avatar"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleFileChange"
            />
            <div class="upload-icon" @click="triggerFileInput">
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div
              v-if="employee.image || employee.imageSrc"
              class="avatar-preview"
            >
              <img
                :src="employee.imageSrc ? employee.imageSrc : employee.image"
                alt="Avatar Preview"
              />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!-- <span class="error-feedback" v-if="v$.employee.imageSrc.$error"
              >{{ v$.employee.imageSrc.$errors[0].$message }}
            </span> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label>صور الشهادات</label>
          <input
            ref="certificatesInput"
            accept="image/*"
            multiple
            style="display: none"
            type="file"
            @change="handleCertificatesChange"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span>اختيار صور</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(img, index) in Myimages"
              :key="index"
              class="certificate-image"
            >
              <img :src="img.image" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificateOldImage(index)"
              ></i>
            </div>
            <!-- {{ newImages }} -->
            <div
              v-for="(newImage, index) in newImages"
              :key="index"
              class="certificate-image"
            >
              <img :src="newImage.image" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificateNewImage(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الموظف</label>
          <div class="input">
            <input
              id="name"
              v-model="employee.name"
              placeholder="أدخل أسم الموظف"
              type="text"
            />
            <span v-if="v$.employee.name.$error" class="error-feedback">{{
              v$.employee.name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="identitynumber">
            <label for=""> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                v-model="employee.identity_number"
                placeholder="ادخل الرقم الهويه"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ الميلاد </label>
          <div class="input">
            <input
              v-model="employee.date_of_birth"
              :max="new Date().toISOString().split('T')[0]"
              placeholder="ادخل تاريخ الميلاد"
              type="date"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="phone">رقم الهاتف</label>
          <div class="input">
            <input
              id="phone"
              v-model="employee.phone"
              class="no-spinner"
              placeholder="أدخل رقم الهاتف"
              type="number"
            />
            <span v-if="v$.employee.phone.$error" class="error-feedback">{{
              v$.employee.phone.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="email">البريد الالكتروني</label>
          <div class="input">
            <input
              id="email"
              v-model="employee.email"
              placeholder="أدخل البريد الالكتروني"
              type="email"
            />
            <span v-if="v$.employee.email.$error" class="error-feedback">{{
              v$.employee.email.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="age"> العمر </label>
          <div class="input">
            <input
              id="age"
              v-model="employee.age"
              placeholder="أدخل العمر"
              type="number"
            />
            <span v-if="v$.employee.age.$error" class="error-feedback">{{
              v$.employee.age.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 col-12">
          <label for="name">الحاله الأجتماعيه</label>
          <div class="radio">
            <div class="form-check">
              <input
                id="flexRadioDefault3"
                v-model="employee.marital_status"
                class="form-check-input"
                name="marrid"
                type="radio"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault3">
                متزوج
              </label>
            </div>
            <div class="form-check">
              <input
                id="flexRadioDefault4"
                v-model="employee.marital_status"
                checked
                class="form-check-input"
                name="notmarrid"
                type="radio"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault4">
                اعزب
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 col-12">
          <label for="gender">النوع</label>
          <div class="radio">
            <div class="form-check">
              <input
                id="flexRadioDefault1"
                v-model="employee.gender"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                id="flexRadioDefault2"
                v-model="employee.gender"
                class="form-check-input"
                name="flexRadioDefault"
                type="radio"
                value="2"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                أنثي
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-12">
          <label for="name">نوع الهوية</label>
          <div class="radio">
            <div class="form-check">
              <input
                id="flexRadioDefault5"
                v-model="employee.identity_type"
                class="form-check-input"
                name="card"
                type="radio"
                value="0"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                id="flexRadioDefault6"
                v-model="employee.identity_type"
                checked
                class="form-check-input"
                name="stay"
                type="radio"
                value="1"
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الوظيفه</label>
          <multiselect
            v-model="jobType"
            :close-on-select="false"
            :options="postionOptions"
            label="title"
            track-by="id"
            @update:modelValue="updatePostion"
          ></multiselect>
        </div>
      </div>
      <div class="all-btn">
        <button class="save" type="submit">تعديل</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import useVuelidate from "@vuelidate/core";
import { useEmployeesEditStore } from "@/stores/employees/EmployeesEditStore";
import { email, required } from "@vuelidate/validators";
import { mapState } from "pinia";

export default {
  name: "EmployeesEdit",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      store: useEmployeesEditStore(),
      employee: {
        identity_type: "",
        marital_status: "",
        identity_number: "",
        date_of_birth: "",
        certificate_images: [], // Initialize as an empty array
        certificateImagesSrc: [], // Initialize as an empty array
        age: "",
        gender: "",
        name: "",
        phone: "",
        email: "",
        image: null, // Store file object
        imageSrc: "",
        job_type_id: null,

        removedImageIds: [],
      },
      newImages: [],
      jobType: null,
      postionOptions: [],
      // Array to store the IDs of removed images
    };
  },
  validations() {
    return {
      employee: {
        name: { required },
        phone: { required },
        email: { required, email },
        age: { required },
      },
    };
  },
  computed: {
    ...mapState(useEmployeesEditStore, {
      employee: (state) => state.employee,

      Myimages: (state) =>
        state.images.map((img) => ({
          image: img.image,
          id: img.id,
        })),
    }),
  },
  methods: {
    updatePostion() {
      // const foundPosition = this.store.postion.find(
      //   (pos) => pos.title === this.jobTypeIds
      // );
      // if (foundPosition) {
      //   this.employee.job_type_id = foundPosition.id;
      //   console.log(this.employee.job_type_id, "Position found");
      // } else {
      //   this.employee.job_type_id = null; // Or handle it in a different way
      //   console.log("No matching position found for:", this.jobTypeIds);
      // }
    },

    removeImage() {
      this.employee.image = null;
      this.employee.imageSrc = "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.employee.image = file; // Store the file object
        const reader = new FileReader();
        reader.onload = (e) => {
          this.employee.imageSrc = e.target.result; // For preview
        };
        reader.readAsDataURL(file);
      }
    },

    // Method to trigger the file input
    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    // Method to handle file selection and preview
    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.employee.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.Myimages.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    removeCertificateOldImage(index) {
      if (Array.isArray(this.Myimages) && this.Myimages.length > 0) {
        const store = useEmployeesEditStore();
        const removedImageId = this.Myimages[index].id;
        this.Myimages.splice(index, 1);
        this.employee.certificate_images.splice(index, 1);
        if (removedImageId) {
          this.employee.removedImageIds.push(removedImageId);
        }

        store.removeImage(index);
      }
      console.log(this.employee.removedImageIds, "nasra");
    },
    removeCertificateNewImage(index) {
      if (Array.isArray(this.newImages) && this.newImages.length > 0) {
        this.newImages.splice(index, 1); // حذف الصورة من المصفوفة الأصلية
      }
    },
    updateCurricula(value) {
      this.employee.curriculum_ids = value.map((cur) => cur.id);
    },

    async fetchEditData() {
      await this.store.fetchPositions();
      const store = useEmployeesEditStore();
      const id = this.$route.params.id;
      await store.fetchEmployee(id);

      this.postionOptions = this.store.postion;
      this.jobType = this.store.job_type;
      this.employee.image = store.employee.image;
      this.employee.name = store.employee.name;
      this.employee.age = store.employee.age;
      this.employee.certificateImagesSrc = store.employee.certificateImagesSrc;
      this.employee.date_of_birth = store.employee.date_of_birth;
      this.employee.email = store.employee.email;
      this.employee.gender = store.employee.gender;
      this.employee.identity_number = store.employee.identity_number;
      this.employee.identity_type = store.employee.identity_type;
      this.employee.imageSrc = store.employee.imageSrc;
      this.employee.marital_status = store.employee.marital_status;
      this.employee.phone = store.employee.phone;
      this.employee.certificate_images = store.employee.images;

      console.log(this.employee, "form");

      if (
        this.employee.certificate_images &&
        this.employee.certificate_images.length > 0
      ) {
        this.images = this.employee.certificate_images.map((img) => ({
          image: img.image,
        }));
      }
    },
    submitForm() {
      const store = useEmployeesEditStore();
      const id = this.$route.params.id;
      this.employee.job_type_id = this.jobType.id;
      store.updateEmployees(id, this.employee);
    },
  },
  mounted() {
    this.fetchEditData();
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.radio {
  margin-right: 4px;
}
</style>
