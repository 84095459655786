import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useparentsEditStore = defineStore("EmployeesEdit", {
  state: () => ({
    parents: [],
    countries: [],
    bloods: [],
    groups: [],
  }),
  actions: {
    async fetchUserDetails(id) {
      try {
        const response = await axios.post("fetch_user_details", { id });
        if (response.data.status) {
          return response.data.data;
        } else {
          console.log("Error fetching user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    },

    async fetchcountries() {
      try {
        const response = await axios.get("organization_fetch_countries");
        if (response.data.status) {
          this.countries = response.data.data;
          console.log(this.countries);
        } else {
          console.log("Error fetching countries");
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    },

    async fetchblood() {
      try {
        const response = await axios.get("organization_fetch_blood_types");
        if (response.data.status) {
          this.bloods = response.data.data;
        } else {
          console.log("Error fetching blood types");
        }
      } catch (error) {
        console.error("Error fetching blood types:", error);
      }
    },

    async fetchgroup() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status) {
          this.groups = response.data.data;
        } else {
          console.log("Error fetching groups");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },

    async Editparents(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (
            Array.isArray(data[key]) &&
            key !== "images" &&
            key !== "groups"
          ) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (
            key !== "imageSrc" &&
            key !== "country" &&
            key !== "blood_type" &&
            key !== "groups"
          ) {
            if (key === "image") {
              if (data[key] instanceof File) {
                formData.append("image", data[key]);
              }
            } else {
              formData.append(key, data[key]);
            }
          }
        });

        const response = await axios.post("edit_user", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status) {
          Swal.fire(
            "Success",
            "Employee has been saved successfully.",
            "success"
          );
          this.parents.push(response.data.data); // Track edited parents
          router.push("/index-parent");
        } else {
          Swal.fire(
            "Error",
            `There was a problem saving the employee: ${response.data.message}`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error saving employee:", error);
        Swal.fire("Error", "There was a problem saving the employee.", "error");
      }
    },
  },
});
