import { defineStore } from "pinia";
import axios from "axios";
import swal from "sweetalert2";

export const useCounterAddStore = defineStore("counterAdd", {
    state: () => {
        return {
            counter : [],
        };
    },
    actions: {
        async addCounter(CounterData) {
            try {
              const formData = new FormData();
              Object.keys(CounterData).forEach((key) => {
                if (Array.isArray(CounterData[key])) {
                    CounterData[key].forEach((item) => {
                    formData.append(`${key}[]`, item);
                  });
                } else {
                  formData.append(key, CounterData[key]);
                }
              });
      
              const response = await axios.post("add_statistic", formData, {
                header: { "Content-Type": "multipart/form-data" },
              });
      
              if (response.data.status) {
                this.counter.push(response.data.data); 
                swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "counter Added Successfully",
                });
              } else {
                swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.data.message,
                });
              }
            } catch (error) {
              console.error("Error while adding counter:", error);
              swal.fire({
                icon: "error",
                title: "Error",
                text:
                  error.response?.data?.message || "An unexpected error occurred.",
              });
            }
          },
    },
});