<template>
  <div class="home">
    <!-- Start Header View -->

    <header-view />
    <body-view />
  </div>
</template>

<script>
import HeaderView from "@/components/home/HeaderView.vue";
import BodyView from "@/components/home/BodyView.vue";
export default {
  name: "HomeView",
  components: { HeaderView, BodyView },
};
</script>
