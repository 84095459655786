<template>
  <div class="employees">
    <HeadersPages
      button="+ اضافة المكتبات الالكترونية"
      link="/add-electroniclibrary"
      title="المكتبات الالكترونية"
    />
    <div class="alll">
      <TablesPageVue
        :editLink="editLink"
        :headers="tableHeaders"
        :ismaster="ismaster"
        :pages="tablePages"
        :rows="tableRows"
        :showSelect="false"
        :viewLink="viewLink"
        @delete="deletelibrary"
      />
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useLibraryStore } from "@/stores/librarires/libraryStoreIndex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: ["ID", "الملف", "الاسم"],
      editLink: "/edit-electroniclibrary",
      viewLink: "/view-electroniclibrary",
    };
  },
  computed: {
    ...mapState(useLibraryStore, {
      library: (state) => state.library,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.library, "ssss");
      return this.library.map((emp) => [
        emp.id,
        {
          to: emp.file,
          icon: "fa-solid fa-file-pdf",
        },
        emp.name,
        // emp.email,
        // emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const libraryStore = useLibraryStore();
      libraryStore.fetchlibrary(page);
    },
    async deletelibrary(id) {
      const libraryStore = useLibraryStore();
      console.log(id);

      await libraryStore.deletelibrary(id);
    },
  },

  async mounted() {
    const libraryStore = useLibraryStore();
    await libraryStore.fetchlibrary();
  },
};
</script>
