<template>
  <div class="add-slider">
    <HeaderPages title=" إضافة اسليدر جديدة" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar"> * اضافة صورة للسليدر</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="name"> الاسم</label>
          <div class="input">
            <input type="text" id="name" placeholder="اكتب هنا " />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="name"> لينك</label>
          <div class="input">
            <input type="text" id="name" placeholder="اكتب هنا " />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="name"> الوصف</label>
          <br />
          <textarea id="w3review" name="w3review"></textarea>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      form: {
        imageSrc: "",
      },
      CurriculaOptions: [],
    };
  },

  methods: {
    // add img 1
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    // add img 1
  },
};
</script>
