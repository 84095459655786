import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useBlogHashtagAddStore = defineStore("blogHashtagAdd", {
  state: () => ({
    blogHashtag: {},
  }),
  actions: {
    async addBlogHashtag(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        const response = await axios.post("add_blog_hashtag", formData);
        if (response.status) {
          this.blogHashtag = response.data;
          Swal.fire({
            icon: "success",
            title: "Blog Hashtag added successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          await router.push("/index-hashtag");
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
  },
});
