<template>
  <div class="Groups-add">
    <header-pages title="اضافة اسكرين" :showButton="false" link="/add-screen" />
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <span class="error-feedback" v-if="v$.form.image.$error">{{
              getErrorMessage(v$.form.image)
            }}</span>
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save" @click="Save()">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useVuelidate from "@vuelidate/core";
import { useScreenAddStore } from "@/stores/Screenss/screenAddStore";
import { required } from "@vuelidate/validators";
import { mapState } from "pinia";
import Swal from "sweetalert2";

export default {
  components: { HeaderPages },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        imageSrc: "",
        image: null,
      },
    };
  },
  validations() {
    return {
      form: {
        image: { required },
      },
    };
  },
  computed: {
    ...mapState(useScreenAddStore, {
      screens: (state) => state.screens,
    }),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    async submitForm() {
      try {
        const screenStore = useScreenAddStore();
        if (!screenStore) {
          // throw new Error("Failed to load screens store");
        }
        if (!this.form.imageSrc) {
          // Swal.fire("Error", "Please fill in all fields", "error");
          return;
        }
        await screenStore.Addserves(this.form);
        this.$router.push("/index-screen");
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    Save() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
  },
};
</script>

<style>
</style>