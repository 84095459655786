import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import swal from "sweetalert2";
import router from "@/router";

export const useCoursesEditStore = defineStore("coursesEdit", {
  state: () => ({
    coursesDetails: {},
    seasonStudies: [],
    curriculums: [],
    Stages: [],
    disabilities: [],
    curriculum_id: [],
  }),
  actions: {
    async fetchCoursesDetails(id) {
      try {
        const response = await axios.post("fetch_course_details", { id: id });
        if (response.data.status === true) {
          // console.log(response.data.data, "data");
          this.coursesDetails = response.data.data;
        } else {
          console.log("Error fetching courses data");
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchSeasons() {
      try {
        const response = await axios.get("organization_fetch_seasons");
        if (response.data.status) {
          this.seasonStudies = response.data.data;
        } else {
          console.error("Error fetching seasons:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        if (response.data.status) {
          this.curriculums = response.data.data;
        } else {
          console.error("Error fetching curriculums:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchCurricula() {
      try {
        const response = await axios.post("organization_fetch_curriculums");
        if (response.data.status) {
          this.Curricula = response.data.data.data;
          this.curriculum_id = this.Curricula.map((Cur) => Cur.id);
        } else {
          console.error("Error fetching curricula:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchStages() {
      try {
        const response = await axios.post("organization_fetch_stages");
        if (response.data.status) {
          this.Stages = response.data.data.data;
          this.stages_id = this.Stages.map((stage) => stage.id);
        } else {
          console.error("Error fetching stages:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async fetchDisabilities() {
      const response = await axios.post("organization_fetch_disabilities");
      if (response.data.status === true) {
        this.disabilities = response.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchYears() {
      const response = await axios.get("organization_fetch_years");
      if (response.data.status === true) {
        this.years = response.data.data;
      } else {
        console.log("Error fetching years.");
      }
    },
    async updateCourse(id, updatedData) {
      try {
        const response = await axios.post("edit_course", {
          id,
          ...updatedData,
        });
        if (response.data.status) {
          this.courses = response.data.data.data;
          Swal.fire("Success", "Course has been updated.", "success");
          router.push("/courses");
        } else {
          console.error("Error updating course:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
        swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
  },
});
