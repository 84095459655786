<template>
  <div class="Categories">
    <HeadersPages title=" الاقسام" button="+ اضافة  قسم" link="/add-category" />
    <div class="alll">

    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="deleteCategory"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  

  </div>
</div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useCategoryStore } from "@/stores/category/categoryStoreIndex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "CategoriesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: ["ID", "اسم "],
      editLink: "/edit-category",
      viewLink: "/view-category",
    };
  },
  computed: {
    ...mapState(useCategoryStore, {
      Category: (state) => state.Category,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.category, "ssss");
      return this.Category.map((emp) => [emp.id, emp.title]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const categoryStore = useCategoryStore();
      categoryStore.fetchCategory(page);
    },
    async deleteCategory(id) {
      const categoryStore = useCategoryStore();
      console.log(id);

      await categoryStore.deleteCategory(id);
    },
  },

  async mounted() {
    const categoryStore = useCategoryStore();
    await categoryStore.fetchCategory();
  },
};
</script>
