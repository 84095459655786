import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useBlogHashtagEditStore = defineStore("blogHashtagEdit", {
  state: () => ({
    blogHashtag: {},
  }),
  actions: {
    getBlogHashtag(id) {
      axios
        .post("/fetch_blog_hashtag_details", { id: id })
        .then((response) => {
          this.blogHashtag = response.data.data;
        });
    },
    async UpdateBlogHashtag(data) {
      this.loading = true;
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "image") {
            if (data[key] instanceof File) {
              formData.append("image", data[key]);
            }
          } else {
            formData.append(key, data[key]);
          }
        });
        const response = await axios
          .post(`edit_blog_hashtag`, formData)
          .then((response) => {
            this.blog = response.data.data;
            this.loading = false;
            Swal.fire({
              icon: "success",
              title: "Blog Hashtag updated successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            router.push("/index-hashtag");
          });
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        })
      }
    },
  },
});
