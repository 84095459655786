import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useSuccesspartnersStore = defineStore("successpartners", {
    state: () => ({
        successpartners: [],
    }),
    actions: {
        async getSuccesspartners() {
            try {
                const response = await axios.post("fetch_partners");
        
                if (response.data.status === true) {
                  this.successpartners = response.data.data;
                  console.log("ssssss", this.successpartners);
                }
              } catch (error) {
                console.error("Error fetching successpartners:", error); 
              }
        },
        async deletePartners(id) {
          
            try {
              const result = await Swal.fire({
                title: "هل انتا متاكد من عملية المسح?",
                text: "لن تتمكن من التراجع عن هذا!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "نعم، احذفه!",
              });
              if (result.isConfirmed) {
                await axios.post("delete_partner", { id });
                this.successpartners = this.successpartners.filter((successpartners) => successpartners.id !== id);
                Swal.fire("تم الحذف!", "تم حذف الشريك", "success");
              }
            } catch (error) {
              console.error("Error deleting successpartners:", error);
              Swal.fire("Error!", "There was an error deleting the successpartners.", "error");
            }
          },
      
      
    },  
});