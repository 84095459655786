<template>
  <div class="serves">
    <HeaderPages
      title="الخدمات"
      button="+ إضافة خدمة جدول"
      link="/add-serves"
    />

    <div class="alll">
    <div class="search">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="بحث عن الخدمة ..."
        v-model="word"
        @input="debouncedSearch"
      />
    </div>
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="deleteServices"
      :ismaster="ismaster"
    />
  </div>

  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { useServicesStore } from "@/stores/services/servicesStoreIndex";
import { mapActions, mapState } from "pinia";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "ServesIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["ID", "صورة", "الاسم", "الوصف"],
      editLink: "/edit-serves",
      viewLink: "/view-serves",
    };
  },
  computed: {
    ...mapState(useServicesStore, {
      ServicesStore: (state) => state.ServicesStore,
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      const dataToDisplay = this.ServicesStore;
      return dataToDisplay.map((service) => [
        service.id,
        service.image,
        service.title,
        service.sub_title,
      ]);
    },
    paginationCurrent() {
      return this.ServicesStore.current_page; // Ensure to adjust based on your store structure
    },
    paginationLast() {
      return this.ServicesStore.last_page; // Ensure to adjust based on your store structure
    },
  },
  methods: {
    async handleSearch() {
      const servicestoreStore = useServicesStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await servicestoreStore.fetchServicesStore(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await servicestoreStore.fetchServicesStore(1, this.word);

      if (servicestoreStore.ServicesStore.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    async deleteServices(id) {
      const servicestoreStore = useServicesStore();
      await servicestoreStore.deleteServicesStore(id);
    },
    async handlePageChange(page) {
      const servicestoreStore = useServicesStore();
      await servicestoreStore.fetchServicesStore(page);
    },
  },
  async mounted() {
    const servessStore = useServicesStore();
    await servessStore.fetchServicesStore();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>

<style scoped>
.error-message {
  color: white;
  background-color: #ef0000a3;
  margin-top: -39px;
  margin-right: 23px;
  width: 97.4%;
  margin-bottom: -25px;
  padding: 8px;
  text-align: center;
  border-radius: 3px;
}
</style>
