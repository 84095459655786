import {defineStore} from "pinia";
import axios from "axios";
import router from "@/router";

export const useCounterEditStore = defineStore("counterEdit", {
    state: () => ({
        counter: {},
    }),

    actions: {
        async getCounter(id) {
            try {
                const response = await axios.post("fetch_statistic_details", {id});
                if (response.data.status) {
                    this.counter = response.data.data;
                } else {
                    console.error("Error fetching counter:", response.data.message);
                }
            } catch (error) {
                console.error("API call failed:", error);
            }
        },

        async updateCounter(data) {
            try {
                const formData = new FormData();
                formData.append("id", data.id);
                formData.append("title", data.title);
                formData.append("sub_title", data.sub_title);
                if (data.image && data.image instanceof File) {

                    formData.append("image", data.image);
                }

                const response = await axios.post("edit_statistic", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status) {
                    this.counter = response.data.data;
                    await router.push("/index-counter")
                } else {
                    console.error("Error updating counter:", response.data.message);
                }
            } catch (error) {
                console.error("API call failed:", error);
            }
        },
    },
});
