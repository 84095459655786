import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useBlogAddStore = defineStore("blogAdd", {
  state: () => ({
    loading: false,
    blogsHashtag: [],
    blogsCategory: [],
  }),
  actions: {
    async createBlog(blog) {
      this.loading = true;
      try {
        const formData = new FormData();
        Object.keys(blog).forEach((key) => {
          // if (key === "image") {
          //   if (blog[key] instanceof File) {
          //     formData.append("image", blog[key]);
          //   }
          // }
          if (Array.isArray(blog[key])) {
            blog[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            formData.append(key, blog[key]);
          }
        });
        const response = await axios.post("/add_blog", blog, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.blog = response.data;
        this.loading = false;
        Swal.fire({
          icon: "success",
          title: "Blog added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        await router.push("/index-blog");
      } catch (error) {
        this.loading = false;
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        })
      }
    },
    async getBlogsHashtag() {
      try {
        const response = await axios.get("/organization_fetch_blog_hashtags");
        this.blogsHashtag = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getBlogsCategory() {
      try {
        const response = await axios.get("/organization_fetch_blog_categories");
        this.blogsCategory = response.data.data;
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        })
      }
    },
  },
});
