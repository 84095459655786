<template>
    <div class="Groups-add">
      <header-pages title="تعديل  رقم " :showButton="false" link="/edit-counter" />
      <form @submit.prevent="updateCounter">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="avatar-uploader">
              <label for="avatar">صوره</label>
              <input
                type="file"
                id="avatar"
                @change="handleFileChange"
                accept="image/*"
                ref="fileInput"
                style="display: none"
              />
              <div
                v-if="!form.imageSrc"
                class="upload-icon"
                @click="triggerFileInput"
              >
                <i class="fa fa-camera"></i>
                <span>اختيار صورة</span>
              </div>
              <div v-if="form.imageSrc" class="avatar-preview">
                <img :src="form.imageSrc" alt="Avatar Preview" />
                <i class="fa fa-times delete-icon" @click="removeImage"></i>
              </div>
              <span class="error-feedback" v-if="v$.form.imageSrc.$error">{{
                getErrorMessage(v$.form.imageSrc)
              }}</span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 mt-3">
            <label for="name">عنوان </label>
            <div class="input">
              <input
                type="text"
                id="name"
                placeholder="اكتب هنا "
                v-model="form.sub_title"
              />
            </div>
            <span class="error-feedback" v-if="v$.form.sub_title.$error">{{
              getErrorMessage(v$.form.sub_title)
            }}</span>
          </div>
          <div class="col-lg-6 col-md-6 col-12 mt-3">
            <label for="name">وصف </label>
            <div class="input">
              <input
                type="text"
                id="name"
                placeholder="اكتب هنا "
                v-model="form.title"
              />
              <span class="error-feedback" v-if="v$.form.title.$error">{{
                getErrorMessage(v$.form.title)
              }}</span>
            </div>
          </div>
        </div>
        <div class="all-btn">
          <button type="submit" class="save" @click="Edit()">حفظ</button>
          <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
        </div>
      </form>
    </div>
  </template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useValidate from "@vuelidate/core";
import { useCounterEditStore } from "@/stores/CounterHeader/CounterEditStore";
import Swal from "sweetalert2";
import { required } from "@vuelidate/validators";
export default {
  components: { HeaderPages },
  data() {
    return {
        v$: useValidate(),
      form: {
        imageSrc: null,
        sub_title: "",
        title: "",
      },
    };
  },
  validations() {
    return {
      form: {
        imageSrc: { required },
        sub_title: { required },
        title: { required },
      },
    };
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    async fetchEditData() {
      const store = useCounterEditStore();
      const id = this.$route.params.id;
      await store.getCounter(id);
      this.form = { ...store.counter };
      this.form.imageSrc = store.counter.image;

    },
    async updateCounter() {
      if (
        !this.form.title ||
        !this.form.sub_title ||
        !this.form.image
      ) {
        Swal.fire("Error", "Please fill in all fields", "error");
        return;
      }

      const store = useCounterEditStore();

      await store.updateCounter(this.form);

    },
    Edit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },

  },
    mounted() {
    this.fetchEditData();
  },

}
</script>

<style>

</style>