import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useHeaderStore = defineStore("header", {
  state: () => ({
    header: [],
  }),
  actions: {
    async getHeader() {
      try {
        const response = await axios.post("organization_fetch_headers");

        if (response.data.status === true) {
          this.header = response.data.data;
          // console.log("ssssss", this.header);
        }
      } catch (error) {
        console.error("Error fetching headers:", error); // Modify this line
      }
    },

    async deleteHeader(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("organization_delete_header", { id });
          this.header = this.header.filter((header) => header.id !== id);
          Swal.fire("تم الحذف!", "تم حذف الهيدر", "success");
        }
      } catch (error) {
        console.error("Error deleting header:", error);
        Swal.fire("Error!", "There was an error deleting the header.", "error");
      }
    },
  },
});
