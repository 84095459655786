<template>
  <div class="main-live">
    <div class="user">
      <div class="heading">
        <h4>كل الطلاب (30)</h4>
      </div>
      <ul >
        <li>
          <div class="profile" v-for="user in users" :key="user">
            <div class="img-name" >
              <img :src="user.user_image" alt="" />

              <h4>{{ user.user_name }}</h4>
            </div>
            <div class="drop">
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">تقييم الطالب</a></li>
                  <li>
                    <a class="dropdown-item" href="#">ارسال رسالة لولى الأمر</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">ارسال رسالة للطالب</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="live-camera" id="camera">
      <iframe
        id="iframe"
        title="100ms-app"
        allow="camera *;microphone *;display-capture *"
        :src="`https://crazy-livestream-1045.app.100ms.live/meeting/${hostcode}`"
        style="width: 100%; border: 0; border-radius: 10px"
        @load="onJoin"
      ></iframe>

      <!-- إظهار زر الخروج فقط عندما يكون المستخدم قد انضم -->
      <div class="leave-button" v-if="joined">
        <button @click="leaveMeeting">
          <i class="fa-solid fa-right-from-bracket"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      hostcode: this.$route.query.host_code || "",
      session_id: this.$route.query.session_id || "",
      joined: false,
      student: [],
      users : [],
    };
  },
  mounted() {
    if (this.hostcode && this.session_id) {
      const { pathname } = window.location;
      window.history.replaceState({}, document.title, pathname);
    }
  },
  methods: {
    onJoin() {
      setTimeout(() => {
        this.joined = true;
      }, 3500);
    },
    async leaveMeeting() {
      const response = await axios.post("organization_leave", {
        session_id: this.session_id,
      });
      if (response.data.status === true) {
        this.$router.push("/");
        Swal.fire("تم الخروج", "تم الخروج", "success");
      } else {
        console.log("erorr");
        Swal.fire("خطاء", "خطاء", "error");
      }
    },
    async fetchstudent() {
      const resstu = await axios.post("organization_attendance", {
        session_id: this.session_id,
      });
      if (resstu.data.status === true) {
        this.student = resstu.data.data;
      } else {
        console.log(resstu.data.message);
      }
    },
    async fetchusers() {
      try {
        const response = await axios.post("fetch_attendance");
        if (response.data.status === true) {
          this.users = response.data.data.data; 
        } else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
  },
  mounted() {
    this.fetchstudent();
    this.fetchusers();
  },
};
</script>
<style scoped lang="scss">
button.hms-ui-c-iKBXPV.hms-ui-c-bIwPpW {
  display: none !important;
}
.hms-ui-c-bIwPpW {
  display: none !important;
}
button.hms-ui-c-iKBXPV.hms-ui-c-bIwPpW.hms-ui-c-iKBXPV-ijpWFml-css {
  display: none !important;
}

</style>
