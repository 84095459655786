<template>
  <div class="edit_teacher">
    <HeaderPages title=" تعديل معلم " :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label>اضافة صورة الشهادادت الحاصل عليها</label>
          <input
            type="file"
            @change="handleCertificatesChange"
            accept="image/*"
            multiple
            ref="certificatesInput"
            style="display: none"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span> اختيار صوره</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(src, index) in form.certificateImagesSrc"
              :key="index"
              class="certificate-image"
            >
              <img :src="src.image || src" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificate(index)"
              ></i>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الوظيفه</label>
          <multiselect
            v-model="form.select_job"
            :options="postionOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            select-label=""
            :hide-selected="true"
            :clear-on-select="true"
          ></multiselect>
        </div> -->
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اسم المعلم</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل  اسم المعلم"
              v-model="form.name"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 my-2">
          <label for="">رقم الهاتف</label>

          <div class="input">
            <input type="فثم" placeholder="رقم الهاتف" v-model="form.phone" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> العمر </label>
          <div class="input">
            <input type="number" placeholder="العمر" v-model="form.age" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ الميلاد </label>
          <div class="input">
            <input
              type="date"
              placeholder="ادخل تاريخ الميلاد"
              v-model="form.date_of_birth"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <!-- {{ form.gender }} -->
          <label for="gender">النوع</label>
          <div class="radio d-flex">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.gender"
                value="1"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.gender"
                class="form-check-input"
                value="0"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                أنثي
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">نوع الهوية</label>
          <div class="radio d-flex">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.identity_type"
                value="0"
                type="radio"
                name="card"
                id="flexRadioDefault5"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.identity_type"
                class="form-check-input"
                value="1"
                type="radio"
                name="stay"
                id="flexRadioDefault6"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
          <div class="identitynumber">
            <label for=""> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="text"
                placeholder="ادخل الرقم الهويه"
                v-model="form.identity_number"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> الوظيفه </label>
          <multiselect
            v-model="form.job_type_id"
            :options="jobsType"
            track-by="id"
            label="title"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="" class=""> الحالة الاجتماعية </label>
          <div class="d-flex">
            <div class="form-check d-flex gap-2">
              <label class="form-check-label" for="flexRadioDefault7">
                متزوج
              </label>
              <input
                v-model="form.marital_status"
                class="form-check-input"
                value="1"
                type="radio"
                name="marital_status"
                id="flexRadioDefault7"
                checked
              />
            </div>
            <div class="form-check d-flex gap-2">
              <label class="form-check-label" for="flexRadioDefault8">
                اعزب
              </label>
              <input
                v-model="form.marital_status"
                class="form-check-input"
                value="0"
                type="radio"
                name="marital_status"
                id="flexRadioDefault8"
                checked
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for=""> المناهج </label>
          <multiselect
            v-model="form.curriculum_ids"
            :options="CurriculaOptions"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكترونى </label>
          <div class="input">
            <input
              type="email"
              id="name"
              placeholder="البريد الالكترونى"
              v-model="form.email"
            />
          </div>
        </div>


        <div class="col-lg-6 col-md-6 col-12">
          <div class="checkbox">
            <form>
              <input
                type="checkbox"
                id="vehicle1"
                name="vehicle1"
                value="Bike"
              />
              <label for="vehicle1"> اضافة المعلم الى قائمة المشرفين </label
              ><br />
            </form>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useTeacherEditStore } from "@/stores/teacher/teacherStoreEdit";
import useVuelidate from "@vuelidate/core";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      jobsType: [],

      maritals_status: [
        {
          id: 0,
          title: "اعزب",
        },
        {
          id: 1,
          title: "متزوج",
        },
      ],
      job_type_id: 1,
      store: useTeacherEditStore(),
      v$: useVuelidate(),
      form: {
        job_type_id: "",
        is_employed: 1,
        // select_job: "",
        name: "",
        date_of_birth: "",
        phone: "",
        gender: 0,
        identity_type: 1,
        identity_number: "",
        marital_status: 0,
        curriculum_ids: "",
        email: "",
        password: "",
        age: "",
        phone: "",
        certificate_images: [],
        certificateImagesSrc: [],
      },
      postionOptions: [
        {
          id: 1,
          title: "معلم",
        },
        {
          id: 2,
          title: "موظف",
        },
      ],
      CurriculaOptions: [],
    };
  },

  methods: {
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);

      // Make sure to check if the arrays are initialized
      if (!this.form.certificate_images) {
        this.form.certificate_images = [];
      }
      if (!this.form.certificateImagesSrc) {
        this.form.certificateImagesSrc = [];
      }

      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    removeCertificate(index) {
      this.form.certificateImagesSrc.splice(index, 1);
    },

    submitForm() {
      this.form.job_type_id = this.form.job_type_id?.id;
      this.form.curriculum_ids = this.form.curriculum_ids.map((item) => {
        return item.id;
      });

      this.store.EditTeacher(this.form);
    },
  },
  mounted() {
    this.store.getCurriculums().then(() => {
      this.CurriculaOptions = this.store.curriculums;
    });
    this.store.getJobTypes().then(() => {
      this.jobsType = this.store.jobTypes;
    });

    this.store.getTeacher(this.$route.params.id).then(() => {
      this.form = this.store.teacher;
      // console.log(this.store.teacher, "teacher");
      this.form.imageSrc = this.store.teacher.image;

      if (this.store.teacher.images) {
        this.form.certificateImagesSrc = this.store.teacher.images;
        this.form.curriculum_ids = this.store.teacher.curriculums;
        this.form.job_type_id = this.store.teacher.job_type;
      }
    });
  },
};
</script>
