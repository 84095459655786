<template>
  <div class="employees">
    <HeadersPages title="المعلمين" button="+ اضافة معلم" link="/add-teacher" />

    <div class="alll">
    <div class="search">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="بحث عن معلم..."
        v-model="word"
        @input="debouncedSearch"
      />
    </div>
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteEmployee"
    />
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</div>

</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useTeacherStore } from "@/stores/teacher/teacherStoreIndex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { debounce } from "lodash"; // استيراد دالة debounce

import { mapState } from "pinia";

export default {
  name: "TeacherIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word : "",
      errorMessage: "",
      debouncedSearch : null,
      tableHeaders: [
        " رقم المعلم",
        " الصوره",
        " 	اسم المعلم",
        " البريد الالكتروني",
        "	 رقم الهاتف",
      ],
      editLink: "/edit-teacher",
      viewLink: "/view-teacher",
    };
  },
  computed: {
    ...mapState(useTeacherStore, {
      teachers: (state) => state.Teacher,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.teachers, "ssss");
      const dataToDisplay = this.teachers;

      return dataToDisplay.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.email,
        emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const teachersStore = useTeacherStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await teachersStore.fetchTeacher(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await teachersStore.fetchTeacher(1, this.word);

      if (teachersStore.Teacher.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
  
    handlePageChange(page) {
      const teachersStore = useTeacherStore();
      teachersStore.fetchTeacher(page);
    },
    async handleDeleteEmployee(id) {
      const teachersStore = useTeacherStore();
      console.log(id);

      await teachersStore.deleteTeacher(id);
    },
  },

  async mounted() {
    const teachersStore = useTeacherStore();
    await teachersStore.fetchTeacher();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>
<style scoped>
.error-message {
  color: white;
  background-color: #ef0000a3;
  margin-top: -39px;
  margin-right: 23px;
  width: 97.4%;
  margin-bottom: -25px;
  padding: 8px;
  text-align: center;
  border-radius: 3px;
}
</style>
