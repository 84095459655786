import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useQuestionBankAddStore = defineStore("question_bank_add", {
  state: () => ({
    seasons: [],
    curriculums: [],
  }),
  actions: {
    async fetchSeasons() {
      try {
        const response = await axios.get("organization_fetch_seasons");
        if (response.data.status === true) {
          this.seasons = response.data.data;
          console.log(this.seasons);
        } else {
          throw new Error("Failed to fetch seasons data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCurriculums() {
      try {
        const response = await axios.post("organization_fetch_curriculums");
        if (response.data.status === true) {
          this.curriculums = response.data.data.data;
          console.log(this.curriculums);
        } else {
          throw new Error("Failed to fetch curriculums data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addQuestion(questionData) {
      try {
        const response = await axios.post("add_question_bank", questionData);
        if (response.data.status === true) {
          Swal.fire(
            "Success",
            "Question has been saved successfully.",
            "success"
          );

          router.push("/index-question-banks");
        } else {
          throw new Error("Failed to add question");
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
  },
});
