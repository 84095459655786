<template>
  <div class="couses-add">
    <header-pages
      title="تعديل الدوره"
      :showButton="false"
      link="/edit-courses"
    />
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الدورة</label>
          <div class="input">
            <input
              v-model="Courses.name"
              placeholder="أدخل أسم الدورة"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> السنوات الدراسيه </label>
          <multiselect
            v-model="Courses.year_id"
            :options="yearsOptions"
            track-by="id"
            label="title"
            :multiple="false"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Disapilty"> الاعاقات </label>
          <multiselect
            v-model="Courses.disability_ids"
            :options="DisapilityOptions"
            :close-on-select="true"
            track-by="id"
            label="title"
            :multiple="true"
            @update:modelValue="updateDisapility"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> الفصول الدراسيه </label>
          <multiselect
            v-model="Courses.season_id"
            :options="seasonOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> المناهج </label>
          <multiselect
            v-model="Courses.curriculum_id"
            :options="curriculaOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            v-if="showCurriculums"
            :multiple="false"
          ></multiselect>
          <div style="display: flex">
            <input
              type="checkbox"
              v-model="Courses.all_curriculum"
              @change="changeAllCurriculum"
              :checked="!showCurriculums"
            />
            <label for="name"> المنهج كامل</label>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> المراحل </label>
          <multiselect
            v-model="Courses.stage_ids"
            :options="stagesOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import { useCoursesEditStore } from "@/stores/courses/CoursesEditStore";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "CoursesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useCoursesEditStore(),
      showCurriculums: true,
      Courses: {
        name: "",
        year_id: "",
        stage_ids: "",
        curriculum_id: "",
        disability_ids: [],
        season_id: "",
        all_curriculum: false,
      },

      yearsOptions: [],
      stagesOptions: [],
      curriculaOptions: [],
      DisapilityOptions: [],
      seasonOptions: [],
    };
  },
  methods: {
    changeAllCurriculum() {
      this.showCurriculums = !this.showCurriculums;
    },
    // updateYear() {
    //   this.Courses.year_id = this.Courses.year_id.id;
    //   console.log(this.Courses.year_id);
    // },
    updateDisapility() {
      this.Courses.disability_ids = this.Courses.disability_ids;
      console.log(this.Courses.disability_ids);
    },
    async getyears() {
      await this.store.fetchYears();
      this.yearsOptions = this.store.years;
    },
    async getstages() {
      await this.store.fetchStages(this.Courses.curriculum_id.id);
      this.stagesOptions = this.store.Stages;
    },
    async getDisapility() {
      await this.store.fetchDisabilities();
      this.DisapilityOptions = this.store.disabilities;
    },
    async getseasons() {
      await this.store.fetchSeasons();
      this.seasonOptions = this.store.seasonStudies;
    },
    async getcurricula() {
      await this.store.fetchCurricula();
      this.curriculaOptions = this.store.Curricula;
    },

    submitForm() {
      this.Courses.year_id = this.Courses.year_id.id;
      this.Courses.disability_ids = this.Courses.disability_ids.map(
        (item) => item.id
      );
      this.Courses.season_id = this.Courses.season_id.id;
      this.Courses.stage_ids = this.Courses.stage_ids.map((item) => item.id);
      this.Courses.curriculum_id = this.Courses.curriculum_id?.id || null;
      this.Courses.all_curriculum = this.showCurriculums ? 1 : 0;
      this.store.updateCourse(this.$route.params.id, this.Courses);
    },
  },
  async mounted() {
    this.getyears();
    this.getDisapility();
    this.getseasons();
    this.getcurricula();

    await this.store.fetchCoursesDetails(this.$route.params.id);

    this.Courses = { ...this.store.coursesDetails };
    this.showCurriculums =
      this.store.coursesDetails.all_curriculum == 1 ? true : false;

    this.Courses.year_id = this.store.coursesDetails.year;
    this.Courses.season_id = this.store.coursesDetails.season;
    this.Courses.curriculum_id = this.store.coursesDetails.curriculum;
    this.Courses.disability_ids = this.store.coursesDetails.disability_types;
    this.Courses.stage_ids = this.store.coursesDetails.stages;
  },
  watch: {
    "Courses.curriculum_id": function (newVal) {
      if (newVal) {
        this.getstages();
      }
    },
  },
};
</script>
