<template>
  <div class="add-slider">
    <HeaderPages title="من نحن" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <label for="name"> عنــوان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="whowe.title"
            />
          </div>
          <span class="error-feedback" v-if="v$.whowe.title.$error">
            {{ getErrorMessage(v$.whowe.title) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="name"> عنوان فرعي</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="whowe.subtitle"
            />
          </div>
          <span class="error-feedback" v-if="v$.whowe.subtitle.$error">
            {{ getErrorMessage(v$.whowe.subtitle) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="description"> الوصــف</label>
          <br />
          <textarea
            id="description"
            rows="4"
            v-model="whowe.description"
          ></textarea>
          <span class="error-feedback" v-if="v$.whowe.description.$error">
            {{ getErrorMessage(v$.whowe.description) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="fileUpload">ارفع صورة أو فيديو</label>
          <div>
            <input
              type="file"
              id="fileUpload"
              accept="image/*, video/*"
              @change="handleFileUpload"
            />
          </div>
          <div v-if="fileURL">
            <img
              class="up"
              v-if="isImage"
              :src="fileURL"
              alt="Uploaded Image"
              width="100%"
            />
            <video v-if="isVideo" :src="fileURL" controls width="100%" />
          </div>
          <p v-if="uploadError" style="color: red">
            يرجى رفع صورة أو فيديو صحيح
          </p>
          <span class="error-feedback" v-if="v$.whowe.description.$error">
          {{ getErrorMessage(v$.whowe.description) }}
        </span>
        </div>
    
      </div>

      <div class="row">
        <div
          v-for="(image, index) in whowe.features"
          :key="index"
          class="col-lg-6 col-md-6 col-12"
        >
          <div class="contant-uploader">
            <div class="avatar-uploader">
              <label for="avatar"> * اضافة صورة للسليدر</label>
              <input
                type="file"
                :id="'avatar' + index"
                @change="handleFileChange($event, index)"
                accept="image/*"
                ref="fileInput"
                style="display: none"
              />
              <div
                v-if="!image.image"
                class="upload-icon"
                @click="triggerFileInput(index)"
              >
                <i class="fa fa-camera"></i>
                <span>اختيار صورة</span>
              </div>
              <div v-if="image.image" class="avatar-preview">
                <img :src="image.image" alt="Avatar Preview" />
                <i
                  class="fa fa-times delete-icon"
                  @click="removeImage(index)"
                ></i>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <label for="title"> عنوان</label>
              <div class="input">
                <input
                  type="text"
                  :id="'title' + index"
                  placeholder="اكتب هنا "
                  v-model="image.title"
                />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <label for="description"> الوصف</label>
              <br />
              <textarea
                :id="'description' + index"
                v-model="image.description"
              ></textarea>

              <span class="error-feedback" v-if="v$.whowe.description.$error">
                {{ getErrorMessage(v$.whowe.description) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useSubHeadersAddStore } from "@/stores/subHeaders/addSubHeaderStore";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "WhoWeAdd",
  components: {
    HeaderPages,
  },
  data() {
    return {
      v$: useVuelidate(),

      store: useSubHeadersAddStore(),
      whowe: {
        title: "",
        subtitle: "",
        description: "",
        image: null,

        features: [
          { image: null, file: null, title: "", description: "", color: "" },
          { image: null, file: null, title: "", description: "", color: "" },
          { image: null, file: null, title: "", description: "", color: "" },
          { image: null, file: null, title: "", description: "", color: "" },
        ],
      },
      videoFile: null,
      fileURL: null,
      uploadError: false,
      isImage: false,
      isVideo: false,
    };
  },
  validations() {
    return {
      whowe: {
        title: { required },
        subtitle: { required },
        description: { required },
        image: { required },
        features: {
          $each: {
            image: { required },
            title: { required },
            description: { required },
            color: { required },
          },
        },
      },
    };
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    handleFileUpload(event) {
      const file = event.target.files[0];

      if (file) {
        const fileType = file.type;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileURL = e.target.result; // تخزين رابط المعاينة
          this.uploadError = false; // لا يوجد خطأ
          this.isImage = fileType.startsWith("image/");
          this.isVideo = fileType.startsWith("video/");
          this.file = file; // تخزين كائن File
        };
        reader.readAsDataURL(file);
      } else {
        this.uploadError = true; // في حال الملف غير صحيح
        this.fileURL = null;
      }
    },

    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.whowe.features[index].image = e.target.result;
          this.whowe.features[index].file = file; // تخزين كائن File
        };
        reader.readAsDataURL(file);
      }
    },

    triggerFileInput(index) {
      this.$refs.fileInput[index].click();
    },

    dataURLtoBlob(dataURL) {
      if (!dataURL) return null; // Guard against null values

      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
    removeImage(index) {
      this.whowe.features[index].image = "";
      this.whowe.features[index].file = null;
    },

    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) ;
      // تحويل الصور إلى كائنات File
      this.whowe.image = this.dataURLtoBlob(this.fileURL);
      this.whowe.features.forEach((feature, index) => {
        if (feature.image) {
          const blob = this.dataURLtoBlob(feature.image);
          const fileName = `feature_image_${index}.png`; // أو أي اسم تفضله
          feature.file = new File([blob], fileName, { type: "image/png" }); // يمكنك تغيير نوع الصورة حسب الحاجة
        }
      });

      // هنا يمكنك معالجة البيانات التي تم إدخالها وحفظها
      console.log("Form submittedaaaaa:", this.whowe);

      this.store.addSubHeader(this.whowe);
      // يمكنك القيام بإرسال البيانات إلى API أو ما إلى ذلك
    },
  },
};
</script>

<style>
.contant-uploader {
  background: #06797e21;
  padding: 0.5rem;
  border-radius: 7px;
  margin: 1rem 0;
}
.upload-icon {
  cursor: pointer;
}
.avatar-preview {
  position: relative;
}
.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
input#fileUpload {
  border: 1px solid var(--main);
  padding: 0.5rem;
  border-radius: 5px;
}
video {
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
  border-radius: 10px;
}
.up {
  width: 200px;
  height: 100%;
  border-radius: 7px;
}
</style>
