<template>
  <div class="employees">
    <HeadersPages
      title=" الخدمات المميزه"
      button="+ اضافة   خدمه مميزه"
      link="/add-feature-serves"
    />
    <div class="alll">

    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="deleteServices"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>

  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useAllServicesStore } from "@/stores/featureServices/featureservicesStoreIndex";
import { mapActions, mapState } from "pinia";

export default {
  name: "ServesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
  },
  data() {
    return {
      store: useAllServicesStore(),
      tableHeaders: ["id", "صوره", "الاسم", "الوصف"],
      editLink: "/edit-feature-serves",
      viewLink: "/view-feature-serves",
    };
  },
  computed: {
    ...mapState(useAllServicesStore, {
      ServicesStoree: (state) => state.ServicesStoree,
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      if (!Array.isArray(this.ServicesStoree)) {
        return []; // Prevent undefined map error
      }
      return this.ServicesStoree.map((serv) => [
        serv.id,
        serv.image,
        serv.title,
        serv.sub_title,
      ]);
    },
  },
  methods: {
    handlePageChange(page) {
      const servicestoreStoree = useAllServicesStore();
      servicestoreStoree.fetchFeatureServicesStore(page);
    },
    async deleteServices(id) {
      const servicestoreStoree = useAllServicesStore();
      await servicestoreStoree.deleteServicesStore(id);
    },
  },
  async mounted() {
    const servicestoreStoree = useAllServicesStore();
    await servicestoreStoree.fetchFeatureServicesStore();

  },
};
</script>
