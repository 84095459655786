import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useGroupsEditStore = defineStore("groupsEdit", {
  state: () => ({
    courses: [],
    teachers: [],
    courseContents: [],
    disabilities: [],
    days: [],
    group: {},
  }),
  actions: {
    async fetchCourses() {
      const response = await axios.post("fetch_courses");
      if (response.data.status === true) {
        this.courses = response.data.data.data;
        // console.log(response.data.data, "courses");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchTeachers() {
      const response = await axios.post("fetch_teachers");
      if (response.data.status === true) {
        this.teachers = response.data.data.data;
        // console.log(response.data.data.data, "teachers");
      } else {
        console.log("Error fetching disabilities.");
      }
    },

    async fetchDisabilities() {
      const response = await axios.post("organization_fetch_disabilities");
      this.disabilities = response.data.data;
      // console.log(response.data.data, "disabilities");
    },

    async fetchDays() {
      const response = await axios.post("fetch_days");
      if (response.data.status === true) {
        this.days = response.data.data;
        // console.log(response.data.data, "days");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchCourseContent() {
      const response = await axios.post("organization_fetch_stages");
      if (response.data.status === true) {
        this.courseContents = response.data.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },

    async fetchGroupDetails(id) {
      const response = await axios.post("fetch_group_details", { id: id });
      if (response.data.status === true) {
        this.group = response.data.data;
        console.log(response.data.data, "group");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async updateGroup(group) {
      const response = await axios.post("edit_group", group);
      if (response.data.status === true) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        await router.push("/groups");
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
});
