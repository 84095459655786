import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useScreenEditStore = defineStore("screenEdit", {
    state: () => ({
        screen: [],
    }),
    actions: {
        async getScreen(id) {
            try {
                const response = await axios.post("fetch_screen_details", { id });
                if (response.data.status) {
                    this.screen = response.data.data;
                } else {
                    console.error("Error fetching screen:", response.data.message);
                }
            } catch (error) {
                console.error("API call failed:", error);
            }
        },

        async updateScreen(formData) {
            try {
                const response = await axios.post("edit_screen", formData, {
                    screen: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status) {
                    this.screen = response.data.data;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Screen Updated Successfully",
                    })
                    await router.push("/index-screen");
                } else {
                    console.error("Error updating screen:", response.data.message);
                }
            } catch (error) {
                console.error("API call failed:", error);
            }
        },
    },
});
