<template>
  <div class="add_student">
    <header-pages
      title=" إضافة طالب جديد "
      :showButton="false"
      link="/groups"
    />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!student.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
              style="margin-top: 0"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="student.imageSrc" class="avatar-preview">
              <img :src="student.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <span class="error-feedback" v-if="v$.student.imageSrc.$error">
              {{ getErrorMessage(v$.student.imageSrc) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اسم الطالب </label>
          <div class="input">
            <input
              type="text"
              placeholder=" ادحل اسم الطالب"
              v-model="student.name"
            />
          </div>
          <span class="error-feedback" v-if="v$.student.name.$error">
            {{ getErrorMessage(v$.student.name) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكتروني </label>
          <div class="input">
            <input
              type="email"
              placeholder="ادخل البريد الالكتروني"
              v-model="student.email"
            />
          </div>
          <span class="error-feedback" v-if="v$.student.email.$error">
            {{ getErrorMessage(v$.student.email) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input">
            <input
              type="password"
              placeholder="كلمة المرور"
              v-model="student.password"
            />
          </div>
          <span class="error-feedback" v-if="v$.student.password.$error">
            {{ getErrorMessage(v$.student.password) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تأكيد كلمة المرور</label>
          <div class="input">
            <input
              type="password"
              placeholder="تأكيد كلمة المرور"
              v-model="student.password_confirmation"
            />
          </div>
          <span
            class="error-feedback"
            v-if="v$.student.password_confirmation.$error"
          >
            {{ getErrorMessage(v$.student.password_confirmation) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> تاريخ الميلاد </label>
            <input v-model="student.date_of_birth" type="date" />
          </div>
          <span class="error-feedback" v-if="v$.student.date_of_birth.$error">
            {{ getErrorMessage(v$.student.date_of_birth) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> العمر </label>
            <input v-model="student.age" type="number" />
          </div>
          <span class="error-feedback" v-if="v$.student.age.$error">
            {{ getErrorMessage(v$.student.age) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تفاصيل العنوان </label>
          <div class="input">
            <input
              type="text"
              placeholder=" اكتب تفاصيل العنوان"
              v-model="student.address"
            />
          </div>
          <span class="error-feedback" v-if="v$.student.address.$error">
            {{ getErrorMessage(v$.student.address) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> رقم الجوال </label>
          <div class="input">
            <input
              type="text"
              placeholder="رقم الجوال"
              v-model="student.phone"
            />
          </div>
          <span class="error-feedback" v-if="v$.student.phone.$error">
            {{ getErrorMessage(v$.student.phone) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">فصيلة الدم</label>
          <multiselect
            v-model="student.blood_type_id"
            :options="bloodOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
          ></multiselect>
          <span class="error-feedback" v-if="v$.student.blood_type_id.$error">
            {{ getErrorMessage(v$.student.blood_type_id) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختر المجموعه</label>
          <multiselect
            v-model="student.group_ids"
            :options="groupOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
            @update:modelValue="updategroup"
          ></multiselect>
          <span class="error-feedback" v-if="v$.student.group_ids.$error">
            {{ getErrorMessage(v$.student.group_ids) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الدوله</label>
          <multiselect
            v-model="student.country_id"
            :options="countryptions"
            :close-on-select="false"
            track-by="id"
            label="title"
          ></multiselect>
          <span class="error-feedback" v-if="v$.student.country_id.$error">
            {{ getErrorMessage(v$.student.country_id) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="identitynumber">
            <label for="identity_number"> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="text"
                placeholder="ادخل رقم الهويه"
                v-model="student.identity_number"
                id="identity_number"
              />
            </div>
          </div>

          <span
            class="error-feedback"
            v-if="v$.student.identity_number.$error"
          >
            {{ getErrorMessage(v$.student.identity_number) }}
          </span>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mt-3">
          <label for="identity_type">نوع الهوية</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="student.identity_type"
                value="0"
                type="radio"
                name="identity_type"
                id="flexRadioDefault5"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="student.identity_type"
                class="form-check-input"
                value="1"
                type="radio"
                name="identity_type"
                id="flexRadioDefault6"
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
       
        </div>

        <div class="col-lg-3 col-md-6 col-12 mt-3">
          <label for="gender">نوع</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="student.gender"
                value="0"
                type="radio"
                name="gender"
                id="flexRadioDefault11"
              />
              <label class="form-check-label" for="flexRadioDefault11">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="student.gender"
                class="form-check-input"
                value="1"
                type="radio"
                name="gender"
                id="flexRadioDefault10"
              />
              <label class="form-check-label" for="flexRadioDefault10">
                انثي
              </label>
            </div>
          </div>
        </div>
     
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useStudentsAddStore } from "@/stores/student/studentStoreAdd";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "pinia";
import Swal from "sweetalert2"; 

export default {
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useStudentsAddStore(),
      v$: useVuelidate(),
      student: {
        id: "",
        name: "",
        email: "",
        phone: "",
        password: "",
        identity_type: "",
        identity_number: "",
        date_of_birth: "",
        password_confirmation: "",
        age: "",
        gender: "",
        address: "",
        image: null,
        imageSrc: "",
        blood_type_id: null,
        group_ids: [],
        country_id: "",
        type: 0,
      },
      groupOptions: [],
      countryptions: [],
      bloodOptions: [],
    };
  },

  validations() {
    return {
      student: {
        name: { required },
        email: { required },
        phone: { required },
        password: { required },
        identity_type: { required },
        identity_number: { required },
        date_of_birth: { required },
        password_confirmation: { required },
        blood_type_id: { required },
        group_ids: { required },
        country_id: { required },
        address: { required },
        imageSrc: { required },
        age: { required },
      },
    };
  },

  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    removeImage() {
      this.student.image = null;
      this.student.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.student.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.student.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async submitForm() {
      this.v$.$validate();
      if (this.v$.$error) {
        return;
      }

      // Access student data correctly
      const studentData = { ...this.student };

      // Safely access IDs
      studentData.blood_type_id = studentData.blood_type_id
        ? studentData.blood_type_id.id
        : null;
      studentData.group_ids = studentData.group_ids.map((item) => item.id);
      studentData.country_id = studentData.country_id
        ? studentData.country_id.id
        : null;

      await this.store.addStudents(studentData);
      Swal.fire("Success", "Student has been added successfully.", "success");
    },

    async fetchCountries() {
      await this.store.fetchCountries();
      this.countryptions = this.store.countries;
    },
    async fetchBloods() {
      await this.store.fetchBloods();
      this.bloodOptions = this.store.bloods;
    },
    async fetchGroups() {
      await this.store.fetchGroups();
      this.groupOptions = this.store.groups;
    },
  },
  mounted() {
    this.fetchCountries();
    this.fetchBloods();
    this.fetchGroups();
  },
};
</script>
