import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";
import { filter } from "lodash";

export const useBlogs = defineStore("blogs", {
  state: () => ({
    blogs: [],
  }),

  actions: {
    async getBlogs(page = 1, word = "") {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      try {
        const response = await axios.post(`fetch_blogs?page=${page}`, {
          word: word,
        });
        const paginationStore = usePaginationStore();
        const { current_page, from, last_page, per_page, to, total } =
          response.data.data.meta;
        if (response.data.status) {
          this.blogs = response.data.data.data;
          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
        }

      } catch (error) {
        console.log(error);
      }finally{
        loadingStore.stopLoading();
      }

    },

    async deleteBlog(id) {
      const result = await Swal.fire({
        title: "هل انتا متاكد من عملية المسح?",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!",
      });

      if (result.isConfirmed) {
        await axios.post(`delete_blog`, { id: id });
        this.blogs = this.blogs.filter((emp) => emp.id !== id);
        Swal.fire("تم الحذف!", "تم حذف الأمتحان", "success");
      }
    },
    filterBlogs(word) {
      if (word === "") {
        return this.blogs; 
      } else {
        return this.blogs.filter(
          (exa) =>
            // exa.image.toLowerCase().includes(word.toLowerCase()) ||
            exa.title.toLowerCase().includes(word.toLowerCase()) || 
            exa.description.includes(word) 
        );
      }
    },
  },
});
