<template>
  <div class="edit-serves">
    <HeaderPages title="تعديل الخدمات " :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-12">
          <label for="name"> الاسم</label>
          <div class="input">
            <input
              type="text"
              v-model="form.title"
              id="name"
              placeholder="اكتب هنا "
              required
            />
          </div>
        </div>

        <div class="col-lg-7 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">اضافة صورة</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-md-12 col-12">
          <label for="description"> الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea
              id="description"
              v-model="form.sub_title"
              name="description"
              required
            ></textarea>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useServicesStoreeditStore } from "@/stores/services/servicesStoreEdit";

export default {
  name: "ServicesEdit",
  components: {
    HeaderPages,
  },
  data() {
    return {
      store: useServicesStoreeditStore(),
      form: {
        imageSrc: "",
        image: null,
        title: "",
        sub_title: "",
      },
    };
  },

  methods: {
    // Handle image upload
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    submitForm() {
      this.store.editServicesStore(this.form);
    },
  },

  async mounted() {
    await this.store.getServicesStore(this.$route.params.id);
    this.form = this.store.ServicesStore;
    this.form.imageSrc = this.store.ServicesStore.image;
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.input-textarea {
  width: 100%;
}

.input-textarea textarea {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid var(--main);
}

.all-btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.save {
  background-color: #007bff;
  color: white;
}

.bake {
  background-color: #f8f9fa;
  color: black;
}
</style>
