import { defineStore } from "pinia";
import axios from "axios";
import swal from "sweetalert2";

export const useSuccesspartnersAddStore = defineStore("successpartnersAdd", {
    state: () => ({
        Successpartners :[]

    }),
    actions: {
    async addSuccesspartners(PartnerData) {
        try {
            const formData = new FormData();
            Object.keys(PartnerData).forEach((key) => {
              if (Array.isArray(PartnerData[key])) {
                PartnerData[key].forEach((item) => {
                  formData.append(`${key}[]`, item);
                });
              } else {
                formData.append(key, PartnerData[key]);
              }
            });
    
            const response = await axios.post("add_partner", formData, {
                Successpartners: { "Content-Type": "multipart/form-data" },
            });
    
            if (response.data.status) {
              this.Successpartners.push(response.data.data); 
              swal.fire({
                icon: "success",
                title: "Success",
                text: "Successpartners Added Successfully",
              });
            } else {
              swal.fire({
                icon: "error",
                title: "Error",
                text: response.data.message,
              });
            }
          } catch (error) {
            console.error("Error while adding Successpartners:", error);
            swal.fire({
              icon: "error",
              title: "Error",
              text:
                error.response?.data?.message || "An unexpected error occurred.",
            });
          }
    }

    }
});